export const actionTypes = {
  GET_NEWS_ARTICLE_USERS_READS: 'GET_NEWS_ARTICLE_USERS_READS',
  GET_NEWS_ARTICLE_USERS_READS_SUCCESS: 'GET_NEWS_ARTICLE_USERS_READS_SUCCESS',

  RESET_STATE_NEWS_ARTICLE_USERS_READS: 'RESET_STATE_NEWS_ARTICLE_USERS_READS',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_NEWS_ARTICLE_USERS_READS });

export const getNewsArticleUsersReads = (params) => ({ type: actionTypes.GET_NEWS_ARTICLE_USERS_READS, params });
export const getNewsArticleUsersReadsSuccess = (params) => ({ type: actionTypes.GET_NEWS_ARTICLE_USERS_READS_SUCCESS, ...params });

