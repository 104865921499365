export const actionTypes = {
  GET_MY_TEAM_LATEST_DOCS: 'GET_MY_TEAM_LATEST_DOCS',
  GET_MY_TEAM_LATEST_DOCS_REQUEST: 'GET_MY_TEAM_LATEST_DOCS_REQUEST',
  GET_MY_TEAM_LATEST_DOCS_SUCCESS: 'GET_MY_TEAM_LATEST_DOCS_SUCCESS',
  GET_MY_TEAM_LATEST_DOCS_ERROR: 'GET_MY_TEAM_LATEST_DOCS_ERROR',

  RESET_STATE_MY_TEAM_LATEST_DOCS: 'RESET_STATE_MY_TEAM_LATEST_DOCS',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_MY_TEAM_LATEST_DOCS });


export const getDocs = (params) => ({ type: actionTypes.GET_MY_TEAM_LATEST_DOCS, params });

export const getDocsRequest = () => ({ type: actionTypes.GET_MY_TEAM_LATEST_DOCS_REQUEST });

export const getDocsSuccess = (params) => ({ type: actionTypes.GET_MY_TEAM_LATEST_DOCS_SUCCESS, ...params });

export const getDocsError = (error) => ({ type: actionTypes.GET_MY_TEAM_LATEST_DOCS_ERROR, error });
