import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import { store } from 'react-notifications-component';

import {
  apiGetNewsComments,
  apiCreateNewsComment,
  apiUpdateNewsComment,
  apiDeleteNewsComment,
} from './api';

import {
  actionTypes,

  getNewsCommentsRequest,
  getNewsCommentsSuccess,
  getNewsCommentsError,

  setCreateCommentText,
  createNewsCommentRequest,
  createNewsCommentSuccess,
  createNewsCommentError,

  setReplyCommentParent,
  setReplyCommentText,

  setUpdateComment,
  setUpdateCommentText,
  updateNewsCommentRequest,
  updateNewsCommentSuccess,
  updateNewsCommentError,

  deleteNewsCommentRequest,
  deleteNewsCommentSuccess,
  deleteNewsCommentError,

  addNewsComment,
  removeNewsComment,
  changeNewsComment,
} from './actions';


function* getNewsCommentsSaga(data) {
  try {
    yield put(getNewsCommentsRequest());

    const { data: { data: newsComments } } = yield call(() => apiGetNewsComments({
      newsId: data.params.newsId,
    }));

    yield put(getNewsCommentsSuccess({
      newsComments,
    }));
  } catch (error) {
    yield put(getNewsCommentsError(error));
  }
}

function* createNewsCommentSaga(action) {
  try {
    console.log('createNewsCommentSaga');
    console.log('action.params', action.params);

    const { user } = yield select(({ userService }) => userService);

    const data = action.params;

    const { data: { data: newsComment } } = yield call(() => apiCreateNewsComment(data));

    console.log('created news comment', newsComment);

    yield put(createNewsCommentSuccess());

    if (data.replyCommentId) {
      yield put(setReplyCommentParent({ replyCommentParent: null }));
      yield put(setReplyCommentText({ replyCommentParent: '' }));

      yield put(addNewsComment({
        newsComment: {
          ...newsComment,
          createdByUser: user,
        }
      }));
    } else {
      yield put(setCreateCommentText({ createCommentText: '' }));
      yield put(addNewsComment({
        newsComment: {
          ...newsComment,
          createdByUser: user,
          replies: [],
        }
      }));
    }

    store.addNotification({
      type: 'success',
      message: 'Комментарий опубликован',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 3000,
      },
    });
  } catch (error) {
    console.log('CREATE NEWS COMMENT ERROR', error);

    yield put(createNewsCommentError());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Комментарий не опубликован',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}


function* updateNewsCommentSaga(action) {
  try {
    console.log('updateNewsCommentSaga');
    console.log('action.params', action.params);

    const data = action.params;

    const { data: { data: newsComment } } = yield call(() => apiUpdateNewsComment(data));

    console.log('updated news comment', newsComment);

    yield put(updateNewsCommentSuccess());

    const { newsCommentsList } = yield select(({ newsCommentsService }) => newsCommentsService);

    let updatedNewsComment = newsCommentsList.find((newsComment) => newsComment.id === data.id);

    if (!updatedNewsComment) {
      // Try find comment data in comments replies;

      let reply;

      newsCommentsList.forEach((newsComment) => {
        if (reply) return;

        if (newsComment.replies) {
          reply = newsComment.replies.find((newsComment) => newsComment.id === data.id)
        }
      });

      updatedNewsComment = reply;
    }

    yield put(changeNewsComment({
      newsComment: {
        ...updatedNewsComment,
        text: newsComment.text,
      }
    }));

    yield put(setUpdateComment({
      updateComment: null
    }));

    yield put(setUpdateCommentText({ updateCommentText: '' }));

    store.addNotification({
      type: 'success',
      message: 'Комментарий сохранен',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 3000,
      },
    });
  } catch (error) {
    console.log('UPDATE NEWS COMMENT ERROR', error);

    yield put(updateNewsCommentError());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Комментарий не сохранен',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}


function* deleteNewsCommentSaga(action) {
  console.log('deleteNewsCommentSaga', action);

  const newsCommentId = action.params.newsCommentId;

  try {
    yield put(deleteNewsCommentRequest());

    yield call(() => apiDeleteNewsComment(newsCommentId));

    yield put(deleteNewsCommentSuccess());

    yield put(removeNewsComment({newsCommentId}));

    store.addNotification({
      type: 'success',
      message: 'Комментарий удален',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  } catch (error) {
    console.log('DELETE NEWS COMMENT ERROR', error);

    yield put(deleteNewsCommentError());

    store.addNotification({
      type: 'danger',
      title: 'Ошибка',
      message: 'Комментарий не удален',
      container: 'bottom-right',
      animationIn: ['animate__animated animate__fadeIn'],
      animationOut: ['animate__animated animate__fadeOut'],
      slidingExit: { // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_NEWS_COMMENTS, getNewsCommentsSaga);
  yield takeEvery(actionTypes.CREATE_NEWS_COMMENT, createNewsCommentSaga);
  yield takeEvery(actionTypes.UPDATE_NEWS_COMMENT, updateNewsCommentSaga);
  yield takeEvery(actionTypes.DELETE_NEWS_COMMENT, deleteNewsCommentSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
