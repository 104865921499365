export const actionTypes = {
  GET_DOC: 'GET_DOC',
  GET_DOC_REQUEST: 'GET_DOC_REQUEST',
  GET_DOC_SUCCESS: 'GET_DOC_SUCCESS',
  GET_DOC_ERROR: 'GET_DOC_ERROR',

  FAVORITE_DOC: 'FAVORITE_DOC',
  UNFAVORITE_DOC: 'UNFAVORITE_DOC',

  UPDATE_DOC: 'UPDATE_DOC',


  RESET_STATE_DOC: 'RESET_STATE_DOC',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_DOC });

export const getDoc = (id) => ({ type: actionTypes.GET_DOC, id });

export const getDocRequest = () => ({ type: actionTypes.GET_DOC_REQUEST });

export const getDocSuccess = (data) => ({ type: actionTypes.GET_DOC_SUCCESS, data });

export const getDocError = (error) => ({ type: actionTypes.GET_DOC_ERROR, error });

export const favoriteDoc = (params) => ({ type: actionTypes.FAVORITE_DOC, ...params });
export const unfavoriteDoc = (params) => ({ type: actionTypes.UNFAVORITE_DOC, ...params });

export const updateNewsArticle = (params) => ({ type: actionTypes.UPDATE_DOC, ...params });
