import styled from 'styled-components';
import { styleHelpers } from '@pik/pik-ui';

const S = {};

S.Container = styled.div`
  @keyframes visible {
    0% {
      opacity: 0;
      right: -300px;
    }
    100% {
      opacity: 1;
      right: 20px;
    }
  }

  @keyframes invisible {
    0% {
      opacity: 1;
      right: 20px;
    }
    100% {
      opacity: 0;
      right: -300px;
    }
  }

  position: fixed;
  bottom: 20px;
  width: 300px;
  height: 100px;
  padding: 0 20px 20px 20px;
  border-radius: 20px;
  background: #f0f0f0eb;
  box-shadow: 2px 2px ${styleHelpers.colors.primary};
  z-index: 1000;
  animation: visible 0.3s linear forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  transition: right: 0.3s ease, bottom 0.3s ease;
  cursor: pointer;
  color: gray;

  ${({ index }) => index
    && `
bottom: ${index * 120 + 20}px;
`}

  ${({ isDeleted }) => isDeleted
    && `
    animation: invisible 0.3s linear forwards;
`}
`;

S.Header = styled.div`
  height: 30px;
  width: 120%;
  background-color: ${styleHelpers.colors.primary};
  color: wheat;
  font-weight: 500;
  letter-spacing: -0.07em;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

S.Controls = styled.div`
  display: flex;
  gap: 5px;
`;

S.ControlsItem = styled.div`
  svg {
    transition: fill 0.2s ease, stroke 0.2s ease;

    &:hover {
      fill: white;
    }

    ${({ window }) => window
      && `
      fill: none;
      stroke: black;

      &:hover {
        fill: none;
        stroke: white;
      }
`}
  }
`;

export default S;
