export const actionTypes = {
  GET_NEWS_COMMENTS_PUBLIC: 'GET_NEWS_COMMENTS_PUBLIC',
  GET_NEWS_COMMENTS_PUBLIC_REQUEST: 'GET_NEWS_COMMENTS_PUBLIC_REQUEST',
  GET_NEWS_COMMENTS_PUBLIC_SUCCESS: 'GET_NEWS_COMMENTS_PUBLIC_SUCCESS',
  GET_NEWS_COMMENTS_PUBLIC_ERROR: 'GET_NEWS_COMMENTS_PUBLIC_ERROR',

  RESET_STATE_NEWS_COMMENTS_PUBLIC: 'RESET_STATE_NEWS_COMMENTS_PUBLIC',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_NEWS_COMMENTS_PUBLIC });

export const getNewsComments = (params) => ({ type: actionTypes.GET_NEWS_COMMENTS_PUBLIC, params });
export const getNewsCommentsRequest = () => ({ type: actionTypes.GET_NEWS_COMMENTS_PUBLIC_REQUEST });
export const getNewsCommentsSuccess = (params) => ({ type: actionTypes.GET_NEWS_COMMENTS_PUBLIC_SUCCESS, ...params });
export const getNewsCommentsError = (error) => ({ type: actionTypes.GET_NEWS_COMMENTS_PUBLIC_ERROR, error });
