import convert from 'xml-js';

import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetUserResults,
} from './api';

import {
  actionTypes,
  getUserResultsRequest,
  getUserResultsSuccess,
  getUserResultsError,
} from './actions';

function* getUserResultsSaga(params) {
  try {
    yield put(getUserResultsRequest());

    const response = yield call(() => apiGetUserResults({ userId: params.params.userId }));

    const xmlData = response.data;

    const convertedData = convert.xml2js(xmlData, { compact: true });

    // ".result" could be undefined
    let userResults = convertedData.response.results.result || [];

    if (userResults && !Array.isArray(userResults)) {
      // If user has one result then data is not array but object
      // but we expect array of results

      userResults = [userResults];
    }

    yield put(getUserResultsSuccess({
      userResults,
    }));
  } catch (error) {
    console.log('GET_ISPRING_USER_RESULTS ERROR', error);
    yield put(getUserResultsError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_ISPRING_USER_RESULTS, getUserResultsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
