const makeCommonRoute = (path) => ({
  list: `/${path}`,
  item: (id = "[id]") => `/${path}/${id}`,
});

const urls = {
  login: "/login",
  logout: "/logout",
  tests: makeCommonRoute("tests"),
  news: {
    list: "/",
    champions: "/news/champions",
    item: (id = "[id]") => `/news/${id}`,
    itemChampions: (id = "[id]") => `/news/champions/${id}`,
  },
  newsArticle: "/news/[id]",
  newsArticlePublic: "/news-public/[id]",
  newsPublic: {
    item: (id = "[id]") => `/news-public/${id}`,
  },
  docs: makeCommonRoute("docs"),
  projectsNews: makeCommonRoute("docs/projects"),
  projectsNewsCategories: makeCommonRoute("docs/projects-categories"),
  cultureDocs: makeCommonRoute("docs/culture"),
  teamDocs: {
    team: (usersUnitId = "[usersUnitId]") => `/docs/teams/${usersUnitId}`,
    teamDocsFolder: (
      usersUnitId = "[usersUnitId]",
      docsFolderId = "[docsFolderId]"
    ) => `/docs/teams/${usersUnitId}/folders/${docsFolderId}`,
  },
  favourites: "/favourites",
  learn: {
    index: "/learn",
    tests: "/learn/tests",
    testsNotCompleted: "/learn/tests/not-completed",
    testsCompleted: "/learn/tests/completed",
    courses: "/learn/courses",
    coursesCatalog: "/learn/coursesCatalog",
    selfDevelopment: "/learn/surveys",
    selfDevelopmentNotCompleted: "/learn/surveys/not-completed",
    selfDevelopmentCompleted: "/learn/surveys/completed",
    learnCatalog: "/learn/coursesCatalog",
  },
  culture: "/culture",
  structure: "/structure",
  account: {
    index: "/account",
    tasksList: "/account/tasks",
    item: (taskId = "taskId") => `/account/task/${taskId}`,
    favorites: "/account/favorites",
    allTasks: "/account/allTasks",
    statistics: "/account/statistics",
  },
  useful: {
    index: "/useful",
    profile: {
      index: "/useful/profile",
    },
    standarts: {
      index: "/useful/standarts",
      team: (usersUnitId = "[usersUnitId]") =>
        `/useful/standarts/team/${usersUnitId}`,
    },
    avatars: "/useful/avatars",
    voice: "/useful/voice",
    culture: "/useful/culture",
    novice: "useful/novice/hello",
  },
};

export default urls;
