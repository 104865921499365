export default {
  close: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
    >
      <g id="surface1">
        <path d="M 10.058594 9 L 13.28125 5.78125 C 13.578125 5.488281 13.578125 5.011719 13.28125 4.71875 C 12.988281 4.421875 12.511719 4.421875 12.21875 4.71875 L 9 7.941406 L 5.78125 4.71875 C 5.488281 4.421875 5.011719 4.421875 4.71875 4.71875 C 4.421875 5.011719 4.421875 5.488281 4.71875 5.78125 L 7.941406 9 L 4.71875 12.21875 C 4.574219 12.359375 4.496094 12.550781 4.496094 12.75 C 4.496094 12.949219 4.574219 13.140625 4.71875 13.28125 C 4.859375 13.425781 5.050781 13.503906 5.25 13.503906 C 5.449219 13.503906 5.640625 13.425781 5.78125 13.28125 L 9 10.058594 L 12.21875 13.28125 C 12.359375 13.425781 12.550781 13.503906 12.75 13.503906 C 12.949219 13.503906 13.140625 13.425781 13.28125 13.28125 C 13.425781 13.140625 13.503906 12.949219 13.503906 12.75 C 13.503906 12.550781 13.425781 12.359375 13.28125 12.21875 Z M 10.058594 9 " />
      </g>
    </svg>
  ),

  window: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
    >
      <g id="surface1">
        <path
          d="M 10.5 8.5 L 10.5 10 C 10.5 10.299479 10.299479 10.5 10 10.5 L 2 10.5 C 1.700521 10.5 1.5 10.299479 1.5 10 L 1.5 2 C 1.5 1.700521 1.700521 1.5 2 1.5 L 3.5 1.5 M 6 6 L 10 2 M 6.5 1.5 L 10 1.5 C 10.299479 1.5 10.5 1.700521 10.5 2 L 10.5 5.5 "
          transform="matrix(1.5,0,0,1.5,0,0)"
        />
      </g>
    </svg>
  ),
};
