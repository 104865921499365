import { actionTypes } from './actions';

const initialState = {
  docsFolders: [],
  getDocsFoldersRequest: false,
  getDocsFoldersSuccess: false,
  getDocsFoldersError: false,
};

const handleGetDocsFoldersRequest = (state) => ({
  ...state,
  getDocsFoldersRequest: true,
  getDocsFoldersSuccess: false,
  getDocsFoldersError: false,
});

const handleGetDocsFoldersSuccess = (state, action) => ({
  ...state,
  getDocsFoldersRequest: false,
  getDocsFoldersSuccess: true,
  getDocsFoldersError: false,
  docsFolders: action.data,
});

const handleGetDocsFoldersError = (state) => ({
  ...state,
  getDocsFoldersRequest: false,
  getDocsFoldersSuccess: false,
  getDocsFoldersError: true,
});

const handleResetState = () => ({
  ...initialState,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_DOCS_FOLDERS]: handleResetState,

    [actionTypes.GET_DOCS_FOLDERS_REQUEST]: handleGetDocsFoldersRequest,
    [actionTypes.GET_DOCS_FOLDERS_SUCCESS]: handleGetDocsFoldersSuccess,
    [actionTypes.GET_DOCS_FOLDERS_ERROR]: handleGetDocsFoldersError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
