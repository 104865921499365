export const actionTypes = {
  GET_NEWS_COMMENTS: 'GET_NEWS_COMMENTS',
  GET_NEWS_COMMENTS_REQUEST: 'GET_NEWS_COMMENTS_REQUEST',
  GET_NEWS_COMMENTS_SUCCESS: 'GET_NEWS_COMMENTS_SUCCESS',
  GET_NEWS_COMMENTS_ERROR: 'GET_NEWS_COMMENTS_ERROR',

  SET_CREATE_COMMENT_TEXT: 'SET_CREATE_COMMENT_TEXT',
  CREATE_NEWS_COMMENT: 'CREATE_NEWS_COMMENT',
  CREATE_NEWS_COMMENT_REQUEST: 'CREATE_NEWS_COMMENT_REQUEST',
  CREATE_NEWS_COMMENT_SUCCESS: 'CREATE_NEWS_COMMENT_SUCCESS',
  CREATE_NEWS_COMMENT_ERROR: 'CREATE_NEWS_COMMENT_ERROR',

  SET_REPLY_COMMENT_PARENT: 'SET_REPLY_COMMENT_PARENT',
  SET_REPLY_COMMENT_TEXT: 'SET_REPLY_COMMENT_TEXT',

  SET_UPDATE_COMMENT: 'SET_UPDATE_COMMENT',
  SET_UPDATE_COMMENT_TEXT: 'SET_UPDATE_COMMENT_TEXT',
  UPDATE_NEWS_COMMENT: 'UPDATE_NEWS_COMMENT',
  UPDATE_NEWS_COMMENT_REQUEST: 'UPDATE_NEWS_COMMENT_REQUEST',
  UPDATE_NEWS_COMMENT_SUCCESS: 'UPDATE_NEWS_COMMENT_SUCCESS',
  UPDATE_NEWS_COMMENT_ERROR: 'UPDATE_NEWS_COMMENT_ERROR',

  DELETE_NEWS_COMMENT: 'DELETE_NEWS_COMMENT',
  DELETE_NEWS_COMMENT_REQUEST: 'DELETE_NEWS_COMMENT_REQUEST',
  DELETE_NEWS_COMMENT_SUCCESS: 'DELETE_NEWS_COMMENT_SUCCESS',
  DELETE_NEWS_COMMENT_ERROR: 'DELETE_NEWS_COMMENT_ERROR',

  ADD_NEWS_COMMENT: 'ADD_NEWS_COMMENT',
  REMOVE_NEWS_COMMENT: 'REMOVE_NEWS_COMMENT',
  CHANGE_NEWS_COMMENT: 'CHANGE_NEWS_COMMENT',

  RESET_STATE_NEWS_COMMENTS: 'RESET_STATE_NEWS_COMMENTS',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_NEWS_COMMENTS });

export const getNewsComments = (params) => ({ type: actionTypes.GET_NEWS_COMMENTS, params });
export const getNewsCommentsRequest = () => ({ type: actionTypes.GET_NEWS_COMMENTS_REQUEST });
export const getNewsCommentsSuccess = (params) => ({ type: actionTypes.GET_NEWS_COMMENTS_SUCCESS, ...params });
export const getNewsCommentsError = (error) => ({ type: actionTypes.GET_NEWS_COMMENTS_ERROR, error });

export const setCreateCommentText = (params) => ({ type: actionTypes.SET_CREATE_COMMENT_TEXT, params });
export const createNewsComment = (params) => ({ type: actionTypes.CREATE_NEWS_COMMENT, params });
export const createNewsCommentRequest = () => ({ type: actionTypes.CREATE_NEWS_COMMENT_REQUEST });
export const createNewsCommentSuccess = (data) => ({ type: actionTypes.CREATE_NEWS_COMMENT_SUCCESS, data });
export const createNewsCommentError = () => ({ type: actionTypes.CREATE_NEWS_COMMENT_ERROR });

export const setReplyCommentParent = (params) => ({ type: actionTypes.SET_REPLY_COMMENT_PARENT, params });
export const setReplyCommentText = (params) => ({ type: actionTypes.SET_REPLY_COMMENT_TEXT, params });

export const setUpdateComment = (params) => ({ type: actionTypes.SET_UPDATE_COMMENT, params });
export const setUpdateCommentText = (params) => ({ type: actionTypes.SET_UPDATE_COMMENT_TEXT, params });
export const updateNewsComment = (params) => ({ type: actionTypes.UPDATE_NEWS_COMMENT, params });
export const updateNewsCommentRequest = () => ({ type: actionTypes.UPDATE_NEWS_COMMENT_REQUEST });
export const updateNewsCommentSuccess = (data) => ({ type: actionTypes.UPDATE_NEWS_COMMENT_SUCCESS, data });
export const updateNewsCommentError = () => ({ type: actionTypes.UPDATE_NEWS_COMMENT_ERROR });

export const deleteNewsComment = (params) => ({ type: actionTypes.DELETE_NEWS_COMMENT, params });
export const deleteNewsCommentRequest = () => ({ type: actionTypes.DELETE_NEWS_COMMENT_REQUEST });
export const deleteNewsCommentSuccess = (data) => ({ type: actionTypes.DELETE_NEWS_COMMENT_SUCCESS, data });
export const deleteNewsCommentError = () => ({ type: actionTypes.DELETE_NEWS_COMMENT_ERROR });

export const addNewsComment = (params) => ({ type: actionTypes.ADD_NEWS_COMMENT, params });
export const removeNewsComment = (params) => ({ type: actionTypes.REMOVE_NEWS_COMMENT, params });
export const changeNewsComment = (params) => ({ type: actionTypes.CHANGE_NEWS_COMMENT, params });
