import React from "react";

import localIcons from "./icons";
import S from "./styles";

import UserStore from "../../../stores/user";

function PopupNewUsers(props) {
  const { close } = props;

  const { user, updateUserAction } = UserStore;

  const registerFollowingLink = () => {
    const data = { ...user };
    const currentDate = new Date().toISOString().split("T")[0];

    data.popupNewUser.checkedPopup.status = true;
    data.popupNewUser.checkedPopup.date = currentDate;
    data.popupNewUser.followingLink.status = true;
    data.popupNewUser.followingLink.date = currentDate;
    updateUserAction(data);
  };

  return (
    <S.Container>
      <S.Window>
        <S.Close onClick={close}>{localIcons.close}</S.Close>

        <S.Content>
          <S.Text>
            <S.Title>
              Ура! <img src="/img/newUserPopup/emoji.png" />
            </S.Title>
            <S.Title>Мы тебя ждали!</S.Title>
          </S.Text>

          <S.Image>
            <S.Code>
              <img src="/img/newUserPopup/code.png" />
            </S.Code>
            <S.SubTitle onClick={registerFollowingLink}>
              <a
                href={process.env.TG_LIVE_CHANNEL_URL}
                target="_blank"
                rel="noreferrer"
              >
                Присоединяйся
                <img src="/img/newUserPopup/title.png" />
              </a>
            </S.SubTitle>
          </S.Image>
        </S.Content>
      </S.Window>
    </S.Container>
  );
}

export default PopupNewUsers;
