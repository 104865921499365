import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetCurrentUser,
} from './api';

import {
  actionTypes,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  getCurrentUserError,
} from './actions';

import userStore from '../../stores/user';

function* getCurrentUserSaga() {
  const { initUserData } = userStore;

  try {
    yield put(getCurrentUserRequest());

    const { data: { data: user } } = yield call(() => apiGetCurrentUser());

    const _user = user;

    // In case we use test login
    // if (process.env.NODE_ENV === 'development') {
    //   _user = {
    //     globalId: '1',
    //     email: 'chernovakiu@pik.ru',
    //     // "email": "zuevaaiu@pik.ru",
    //     // "email": "surakotovrsh@pik.ru",
    //     name: 'Йован',
    //     surname: 'Аккерман',
    //     middleName: 'Тестович',
    //     positionId: null,
    //     positionName: 'Инженер',
    //     companyId: null,
    //     companyName: null,
    //     unitId: 'f16d7368-81b0-11eb-844c-00505688ca90',
    //     unitName: null,
    //     employmentStartDate: '2015-04-14 00:00:00',
    //     employmentEndDate: null,
    //     fullName: null,
    //     isSuperAdmin: true,
    //     latestTeamNewsCheck: user.latestTeamNewsCheck,
    //     selectedUnitIdFilter: 'f16d7368-81b0-11eb-844c-00505688ca90',
    //   };
    // }

    yield put(getCurrentUserSuccess({
      user: _user,
    }));

    initUserData(user);
  } catch (error) {
    console.log('GET CURRENT USER ERROR', error && error.response && error.response.data);

    yield put(getCurrentUserError());
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_CURRENT_USER, getCurrentUserSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
