import styled from 'styled-components';
import { styleHelpers, media } from '@pik/pik-ui';

const S = {};

S.Container = styled.div`
  position: relative;
  margin-top: 120px;
  z-index: 1;
  width: 100%;
  padding-bottom: 62px;
`;

S.Content = styled.div`
  height: 100%;
  padding-left: 162px;
  padding-right: 118px;

  @media ${media.max768} {
    margin-left: 48px;
    margin-right: 48px;
    padding: 0;
  }
`;

S.Blocks = styled.div`
  display: flex;
  justify-content: space-between;
`;

S.ContactsBlock = styled.div`
  display: flex;
`;

S.Block = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 16px;
  margin-right: 76px;

  &:last-child {
    margin-right: 0;
  }

  ${({ astro }) => astro
    && `
    text-align: right;
  `};

  > svg {
    position: absolute;
    left: -16px;
    bottom: 2px;
  }
`;

S.Link = styled.a`
  color: #000000;
  transition: color 0.2s ease;

  &:hover,
  &:active {
    cursor: pointer;
    color: ${styleHelpers.colors.primary};
    text-decoration: none;
  }

  &:link {
    text-decoration: underline;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
`;

export default S;
