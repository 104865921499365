export const actionTypes = {
  GET_UNREAD_NEWS: 'GET_UNREAD_NEWS',
  GET_UNREAD_NEWS_REQUEST: 'GET_UNREAD_NEWS_REQUEST',
  GET_UNREAD_NEWS_SUCCESS: 'GET_UNREAD_NEWS_SUCCESS',
  GET_UNREAD_NEWS_ERROR: 'GET_UNREAD_NEWS_ERROR',

  REMOVE_UNREAD_NEWS_ARTICLE_NOTIFICATION: 'REMOVE_UNREAD_NEWS_ARTICLE_NOTIFICATION',

  RESET_STATE_NOTIFICATIONS: 'RESET_STATE_NOTIFICATIONS',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_NOTIFICATIONS });


export const getUnreadNews = (params) => ({ type: actionTypes.GET_UNREAD_NEWS, params });

export const getUnreadNewsRequest = () => ({ type: actionTypes.GET_UNREAD_NEWS_REQUEST });

export const getUnreadNewsSuccess = (params) => ({ type: actionTypes.GET_UNREAD_NEWS_SUCCESS, ...params });

export const getUnreadNewsError = (error) => ({ type: actionTypes.GET_UNREAD_NEWS_ERROR, error });

export const removeUnreadNewsArticleNotification = (params) => ({ type: actionTypes.REMOVE_UNREAD_NEWS_ARTICLE_NOTIFICATION, params });
