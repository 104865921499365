import React, { useRef } from 'react';
import { connect } from 'react-redux';

import useOnClickOutside from '../../../common/clickOutside';

import { close as closeSideNav } from '../../../services/sideNav/actions';

import {
  getUsers,
  resetState as resetStateUsers,
} from '../../../services/iSpringUsers/actions';

import urls from '../../../common/urls';

import AILoginButton from '../../common/AILoginButton';

import Link from './components/Link';

import localIcons from './icons';

import S from './styles';

const LINKS = [
  {
    id: 1,
    title: 'Новости',
    href: urls.news.list,
  },
  {
    id: 2,
    title: 'База знаний',
    href: urls.docs.list,
  },
  {
    id: 3,
    title: 'Обучение',
    href: urls.learn.index,
  },
  {
    id: 4,
    title: 'Избранное',
    href: urls.favourites,
  },
  {
    id: 5,
    title: 'Наша команда',
    href: urls.structure,
  },
  {
    id: 6,
    title: 'Полезное',
    href: urls.useful.index,
  },
];

function SideNav(props) {
  const {
    isAuthorized,

    isOpened,
    closeSideNavAction,

    user,
  } = props;

  const ref = useRef();

  useOnClickOutside(() => {
    closeSideNavAction();
  }, ref.current);

  return (
    <S.Container ref={ref} isOpened={isOpened}>
      <S.Content>
        <S.Nav>
          {LINKS.map(({ id, href, title }) => (
            <Link
              key={id}
              href={isAuthorized ? href : `${urls.login}?callbackPath=${href}`}
              onClickCallback={() => {
                closeSideNavAction();
              }}
            >
              {title}
            </Link>
          ))}
        </S.Nav>

        <S.AILoginButton>
          <AILoginButton isInSideNav />
        </S.AILoginButton>
      </S.Content>

      <S.TelegramLink 
        href={process.env.TG_LIVE_CHANNEL_URL} 
        target="_blank"
      >
        <S.TelegramLinkIcon>{localIcons.telegram}</S.TelegramLinkIcon>
        <S.TelegramLinkText>
          Читайте нас
          {' '}
          <br />
          в телеграме
        </S.TelegramLinkText>
      </S.TelegramLink>

      <S.CloseBtn
        onClick={() => {
          closeSideNavAction();
        }}
      >
        {localIcons.close}
      </S.CloseBtn>
    </S.Container>
  );
}

SideNav.propTypes = {};

const mapStateToProps = ({ sideNavService, userService }) => {
  const { isOpened } = sideNavService;
  const { user } = userService;

  return {
    isOpened,
    user,
  };
};

const mapDispatchToProps = {
  closeSideNavAction: closeSideNav,

  getUsersAction: getUsers,
  resetStateUsersAction: resetStateUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);

// StructureIndex.propTypes = {};

// StructureIndex.defaultProps = {};

// const mapStateToProps = ({ userService }) => {
//   const { user } = userService;

//   return {
//     user,
//   };
// };

// const mapDispatchToProps = {
//   getUsersAction: getUsers,
//   resetStateUsersAction: resetStateUsers,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(StructureIndex);
