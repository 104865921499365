import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiGetNewsComments = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.newsComments.list,
  params,
});

export const apiCreateNewsComment = (data) => axios({
  method: 'POST',
  url: apiUrls.clientApi.newsComments.create,
  data,
});

export const apiUpdateNewsComment = (data) => axios({
  method: 'PUT',
  url: apiUrls.clientApi.newsComments.update,
  data,
});

export const apiDeleteNewsComment = (newsCommentId) => axios({
  method: 'DELETE',
  url: apiUrls.clientApi.newsComments.delete,
  params: {
    newsCommentId,
  },
});
