import { actionTypes } from './actions';

const initialState = {
  newsArticleUsersReads: [],
};

const handleGetNewsArticleUsersReadsSuccess = (state, action) => ({
  ...state,
  newsArticleUsersReads: action.reads,
});

const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_NEWS_ARTICLE_USERS_READS]: handleResetState,

    [actionTypes.GET_NEWS_ARTICLE_USERS_READS_SUCCESS]: handleGetNewsArticleUsersReadsSuccess,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
