import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetNews,
  apiLikeNewsArticle,
  apiDislikeNewsArticle,
} from './api';

import {
  actionTypes,
  getNewsRequest,
  getNewsSuccess,
  getNewsError,

  getMoreNewsRequest,
  getMoreNewsSuccess,
  getMoreNewsError,

  updateNewsArticle,
} from './actions';


function* getNewsSaga(data) {
  try {
    yield put(getNewsRequest());
    const { offset, limit } = yield select(({ newsService }) => newsService);
    const { user } = yield select(({ userService }) => userService);

    const { data: { data: news } } = yield call(() => apiGetNews({
      offset,
      limit: data.params.limit || limit,
      currentUserGlobalId: user.globalId,
    }));

    yield put(getNewsSuccess({
      news,
      offset: offset + limit,
    }));
  } catch (error) {
    console.log('GET NEWS ERROR', error);
    yield put(getNewsError(error));
  }
}

function* getMoreNewsSaga({ search }) {
  try {
    yield put(getMoreNewsRequest());
    const { offset, limit } = yield select(({ newsService }) => newsService);
    const { user } = yield select(({ userService }) => userService);

    const params = {
      offset,
      limit,
      currentUserGlobalId: user.globalId,
    };

    if (search && search.length) {
      params.search = search;
    }

    const { data: { data: news } } = yield call(() => apiGetNews(params));

    yield put(getMoreNewsSuccess({
      news,
      offset: offset + limit,
      isNoMoreNews: !news.length,
    }));
  } catch (error) {
    console.log('MORE NEWS ERROR', error);
    yield put(getMoreNewsError(error));
  }
}


function* likeNewsArticleSaga({ newsId }) {
  try {
    const { newsList } = yield select(({ newsService }) => newsService);
    const { user } = yield select(({ userService }) => userService);

    const editedNewsArticleIndex = newsList.findIndex((newsArticle) => {
      return newsArticle.id === newsId;
    });

    const updatedNewsList = [...newsList];

    const newsArticle = newsList[editedNewsArticleIndex];
    const likes = newsArticle.likes + 1;

    const updatedNewsArticle = {
      ...newsList[editedNewsArticleIndex],
      isLiked: true,
      likes,
    };

    updatedNewsList[editedNewsArticleIndex] = updatedNewsArticle;

    // Like News Article in state right away
    yield put(updateNewsArticle({ newsList: updatedNewsList }));


    // Like API call
    yield call(() => apiLikeNewsArticle({
      newsId,
      userGlobalId: user.globalId,
    }));
  } catch (error) {
    console.log('likeNewsArticleSaga ERROR', error);
  }
}


function* dislikeNewsArticleSaga({ newsId }) {
  try {
    const { newsList } = yield select(({ newsService }) => newsService);
    const { user } = yield select(({ userService }) => userService);

    const editedNewsArticleIndex = newsList.findIndex((newsArticle) => {
      return newsArticle.id === newsId;
    });

    const updatedNewsList = [...newsList];

    const newsArticle = newsList[editedNewsArticleIndex];
    const likes = newsArticle.likes - 1;

    const updatedNewsArticle = {
      ...newsList[editedNewsArticleIndex],
      isLiked: false,
      likes,
    };

    updatedNewsList[editedNewsArticleIndex] = updatedNewsArticle;

    // Like News Article in state right away
    yield put(updateNewsArticle({ newsList: updatedNewsList }));


    // Like API call
    yield call(() => apiDislikeNewsArticle({
      newsId,
      userGlobalId: user.globalId,
    }));
  } catch (error) {
    console.log('likeNewsArticleSaga ERROR', error);
  }
}


function* watchSagas() {
  yield takeEvery(actionTypes.GET_NEWS, getNewsSaga);
  yield takeEvery(actionTypes.GET_MORE_NEWS, getMoreNewsSaga);
  yield takeEvery(actionTypes.LIKE_NEWS_ARTICLE, likeNewsArticleSaga);
  yield takeEvery(actionTypes.DISLIKE_NEWS_ARTICLE, dislikeNewsArticleSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
