import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetNews,
} from './api';

import {
  actionTypes,
  getNewsRequest,
  getNewsSuccess,
  getNewsError,

  getMoreNewsRequest,
  getMoreNewsSuccess,
  getMoreNewsError,
} from './actions';


function* getNewsSaga(data) {
  try {
    yield put(getNewsRequest());
    const { offset, limit } = yield select(({ projectsNewsService }) => projectsNewsService);

    const { data: { data: news } } = yield call(() => apiGetNews({
      offset,
      limit: data.params.limit || limit,
      // currentUserGlobalId: '1',
      categoryId: data.params.categoryId,
    }));

    yield put(getNewsSuccess({
      news,
      offset: offset + limit,
    }));
  } catch (error) {
    console.log('GET NEWS ERROR', error);
    yield put(getNewsError(error));
  }
}

function* getMoreNewsSaga({ search, categoryId }) {
  try {
    yield put(getMoreNewsRequest());
    const { offset, limit } = yield select(({ projectsNewsService }) => projectsNewsService);
    const params = { offset, limit };

    if (search && search.length) {
      params.search = search;
    }

    if (categoryId) {
      params.categoryId = categoryId;
    }

    const { data: { data: news } } = yield call(() => apiGetNews(params));

    yield put(getMoreNewsSuccess({
      news,
      offset: offset + limit,
      isNoMoreNews: !news.length,
    }));
  } catch (error) {
    console.log('MORE NEWS ERROR', error);
    yield put(getMoreNewsError(error));
  }
}


function* watchSagas() {
  yield takeEvery(actionTypes.GET_PROJECTS_NEWS, getNewsSaga);
  yield takeEvery(actionTypes.GET_MORE_PROJECTS_NEWS, getMoreNewsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
