import { actionTypes } from './actions';

const initialState = {
  getUnreadNewsRequest: false,
  getUnreadNewsSuccess: false,
  getUnreadNewsError: false,

  unreadNewsList: [],
};


const handleGetUnreadNewsRequest = (state) => ({
  ...state,
  getUnreadNewsRequest: true,
  getUnreadNewsSuccess: false,
  getUnreadNewsError: false,
});

const handleGetUnreadNewsSuccess = (state, action) => ({
  ...state,
  getUnreadNewsRequest: false,
  getUnreadNewsSuccess: true,
  getUnreadNewsError: false,
  unreadNewsList: action.news,
});

const handleGetUnreadNewsError = (state) => ({
  ...state,
  getUnreadNewsRequest: false,
  getUnreadNewsSuccess: false,
  getUnreadNewsError: true,
});


const handleRemoveUnreadNewsArticleNotification = (state, action) => {
  const newsArticleId = action.params.newsArticleId;

  const foundIndex = state.unreadNewsList.findIndex((unreadNewsArticle) => unreadNewsArticle.id === newsArticleId);

  const unreadNewsList = [...state.unreadNewsList];

  unreadNewsList.splice(foundIndex, 1);

  return {
    ...state,
    unreadNewsList,
  };
};


const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_NOTIFICATIONS]: handleResetState,

    [actionTypes.GET_UNREAD_NEWS_REQUEST]: handleGetUnreadNewsRequest,
    [actionTypes.GET_UNREAD_NEWS_SUCCESS]: handleGetUnreadNewsSuccess,
    [actionTypes.GET_UNREAD_NEWS_ERROR]: handleGetUnreadNewsError,

    [actionTypes.REMOVE_UNREAD_NEWS_ARTICLE_NOTIFICATION]: handleRemoveUnreadNewsArticleNotification,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
