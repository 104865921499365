import { actionTypes } from './actions';

const initialState = {
  getDocsRequest: false,
  getDocsSuccess: false,
  getDocsError: false,
  docs: [],
};

const handleGetDocsRequest = (state) => ({
  ...state,
  getDocsRequest: true,
  getDocsSuccess: false,
  getDocsError: false,
});

const handleGetDocsSuccess = (state, action) => ({
  ...state,
  getDocsRequest: false,
  getDocsSuccess: true,
  getDocsError: false,
  docs: action.docs,
});

const handleGetDocsError = (state) => ({
  ...state,
  getDocsRequest: false,
  getDocsSuccess: false,
  getDocsError: true,
});

const handleResetStateDocs = () => ({
  ...initialState,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_DOCS_FAVOURITES]: handleResetStateDocs,

    [actionTypes.GET_DOCS_FAVOURITES_REQUEST]: handleGetDocsRequest,
    [actionTypes.GET_DOCS_FAVOURITES_SUCCESS]: handleGetDocsSuccess,
    [actionTypes.GET_DOCS_FAVOURITES_ERROR]: handleGetDocsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
