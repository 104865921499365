import styled from 'styled-components';
import { styleHelpers, media } from '@pik/pik-ui';

const S = {};

S.Container = styled.div`
  display: flex;
`;

S.Notifications = styled.div`
  margin-right: 28px;
  display: flex;
  align-items: center;

  @media ${media.mobile} {
    margin-right: 10px;
  }
`;

S.Info = styled.div`
  margin-right: 30px;
  height: 100%;
  padding-top 7px;

  @media ${media.mobile} {
    margin-right: 10px;
  }
`;

S.Name = styled.div`
  margin-bottom: 7px;
  font-size: 14px;
  line-height: 1.2;

  @media ${media.mobile} {
    font-size: 12px;
    margin-bottom: 0px;
  }
`;

S.Logout = styled.button`
  width: 100%;
  font-size: 10px;
  line-height: 1.2;
  color: #a8a8a8;
  text-align: right;
  cursor: pointer;
  transition: color 0.2s ease;

  @media ${media.mobile} {
    text-align: left;
  }

  &:hover,
  &:active {
    color: ${styleHelpers.colors.primary};
  }
`;

S.ImageContainer = styled.div`
  position: relative;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #c4c4c4;
  transition: border 0.2s ease;
  cursor: pointer;

  &:hover {
    border: 1px solid ${styleHelpers.colors.primary};
  }
`;

S.Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;

  ${({ imgUrl }) => imgUrl
    && `
    background-image: url('${imgUrl}');
  `};
`;

export default S;
