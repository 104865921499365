import axios from "axios";
import apiUrls from "../common/apiServices";

import Websocket from "./websocket";

import { makeAutoObservable } from "mobx";

class UserStore {
  user = null;
  favorites = null;

  constructor() {
    makeAutoObservable(this);
  }

  initUserData = (payload: { accessLevel: string }) => {
    this.user = payload;

    if (payload.accessLevel) Websocket.connectWS();
  };

  getUser = () => this.user;

  setUser = (payload) => (this.user = payload);

  updateUserAction = (user) => {
    axios({
      method: "PUT",
      url: apiUrls.clientApi.users.update,
      data: {
        globalId: user.globalId,
        popupNewUser: user.popupNewUser
      },
    })
      .then(() => this.setUser(user))
      .catch((err) => console.log(err));
  };

  getFavorites = (userGlobalId: string, callBack?: Function) => {
    axios({
      method: "GET",
      url: apiUrls.clientApi.usersFavorites.list,
      params: { userGlobalId: userGlobalId },
    }).then((res) => {
      if (callBack) callBack(res.data.data);
      this.favorites = res.data.data;
    });
  };

  postFavorite = (payload: object, callBack: Function) => {
    axios({
      method: "POST",
      url: apiUrls.clientApi.usersFavorites.create,
      params: { ...payload },
    }).then((res) => callBack(res.data.data));
  };

  deleteFavorite = (id: number, callBack: Function) => {
    axios({
      method: "DELETE",
      url: apiUrls.clientApi.usersFavorites.delete,
      params: { id },
    }).then((res) => callBack());
  };
}

export default new UserStore();
