import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiGetNews = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.news.list,
  params,
});

export const apiLikeNewsArticle = (params) => axios({
  method: 'POST',
  url: apiUrls.clientApi.news.like,
  params,
});

export const apiDislikeNewsArticle = (params) => axios({
  method: 'DELETE',
  url: apiUrls.clientApi.news.dislike,
  params,
});
