import styled from 'styled-components';
import { media, styleHelpers } from '@pik/pik-ui';

// import { sizes } from "../../../common/styles/const";

const S = {};

S.Container = styled.div`
  @keyframes anim {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffffc9;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: anim 1s linear;
`;

S.Window = styled.div`
  position: relative;
  width: 70%;
  max-width: 800px;
  height: 270px;
  background: #ff7c5c;
  display: flex;
  justify-content: center;
  align-items: center;
`;

S.Close = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  transition: color 0.2s ease;
  cursor: pointer;

  svg {
    fill: #ffffff;
    transition: opacity 0.2s ease;
  }

  &:hover {
    svg {
      opacity: 0.7;
    }
  }
`;

S.Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 5% 8%;
`;

S.Text = styled.div`
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  cursor: default;
  width: 70%;
`;

S.Title = styled.div`
  font-size: 60px;
  line-height: 66px;
  letter-spacing: -0.03em;
  text-align: left;

  img {
    width: 50px;
  }

  @media (max-width: 1000px) {
    font-size: 40px;
    line-height: 46px;
  }
`;

S.SubTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;

  a {
    color: #ffffff;
    transition: color 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    img {
      width: 80%;
      margin-left: 6px;
    }

    &:hover {
      color: ${styleHelpers.colors.primary};
    }
  }

  @media ${media.mobile} {
    font-size: 20px;
    line-height: 26px;
  }
`;

S.Image = styled.div`
  background: #ff987f;
  border-radius: 18px;
  width: 22%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  img {
    height: 100%;
    width: 100%;
  }
`;

S.Code = styled.div`
  width: 62%;
`;

export default S;
