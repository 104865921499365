import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import { store } from 'react-notifications-component';

import {
  apiGetNewsComments,
} from './api';

import {
  actionTypes,

  getNewsCommentsRequest,
  getNewsCommentsSuccess,
  getNewsCommentsError,
} from './actions';


function* getNewsCommentsSaga(data) {
  try {
    yield put(getNewsCommentsRequest());

    const { data: { data: newsComments } } = yield call(() => apiGetNewsComments({
      newsId: data.params.newsId,
    }));

    yield put(getNewsCommentsSuccess({
      newsComments,
    }));
  } catch (error) {
    yield put(getNewsCommentsError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_NEWS_COMMENTS_PUBLIC, getNewsCommentsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
