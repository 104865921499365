import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { Typography, styleHelpers } from '@pik/pik-ui';

import auth from '../../../common/auth';

import Notifications from '../../common/Notifications';

import S from './styles';

const { logout } = auth;

function User(props) {
  const { user, screenType } = props;

  return (
    <S.Container>
      <S.Notifications>
        <Notifications />
      </S.Notifications>

      <S.Info>
        {user && (
          <S.Name>
            {user.name}
            {' '}
            {user.surname[0].toUpperCase()}
            .
          </S.Name>
        )}

        <S.Logout
          onClick={() => {
            logout({}, window.location.pathname);
          }}
        >
          Выйти
        </S.Logout>
      </S.Info>

      <S.ImageContainer>
        {user.accessLevel ? (
          <Link href="/account">
            {user && (
              <S.Image
                imgUrl={`${process.env.GET_USER_PHOTO_API_URL}${user.globalId}${process.env.GET_USER_PHOTO_API_PARAMS}`}
              />
            )}
          </Link>
        ) : (
          <S.Image
            imgUrl={`${process.env.GET_USER_PHOTO_API_URL}${user.globalId}${process.env.GET_USER_PHOTO_API_PARAMS}`}
          />
        )}
      </S.ImageContainer>
    </S.Container>
  );
}

User.propTypes = {
  screenType: PropTypes.string.isRequired,
  user: PropTypes.shape({}),
};

User.defaultProps = {
  user: {},
};

const mapStateToProps = ({ appService }) => {
  const { screenType } = appService;

  return {
    screenType,
  };
};

export default connect(mapStateToProps)(User);
