import { styleHelpers } from '@pik/pik-ui';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  #modal > div,
  #search-modal > div {
    background: rgba(0, 0, 0, 0.2);
  }

  #modal [width="l"] {
    width: 800px;  

    @media(max-width: 768px) {
      width: 100vw;
    }
  }

  #search-modal [width="l"] {
    width: 955px;

    @media(max-width: 768px) {
      width: 100vw;
    }
  }

  #modal [width="l"] > div:nth-child(2),
  #search-modal [width="l"] > div:nth-child(2) {
    border-radius: 30px;

    @media(max-width: 768px) {
      border-radius: 0;
    }
  }

  #modal [width="l"] > div:nth-child(2) {
    width: 800px; 
    max-height: 80vh; 

    @media(max-width: 768px) {
      width: 100vw;
    }
  }

  #search-modal [width="l"] > div:nth-child(2) {
    width: 955px;
    max-height: 95vh;

    @media(max-width: 768px) {
      width: 100vw;
    }
  }

  @keyframes spin {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }

  .loader {
    position: fixed;
    top: 0;
    z-index: 100;
    display: flex;
    transition: background-color 0.4s linear;
  }

  .loader_start {
    width: 100%;
    height: 100vh;
    background-color: #ffffffab;
  }

  .loader-content {
    position: relative;
    width: 0%;
    height: 0%;
    border-radius: 100%;
    margin: auto;
    animation: spin 5s infinite linear;
    transition: width 0.6s ease, height 0.6s ease;
  }

  .loader-content_start {
    width: 10%;
    height: 10%;
  }

  .loader-content--circle1 {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: rotate(70deg);

    .loader-content--circleInner {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 5px solid rgba(0, 255, 170, 0.7);;
      border-right: none;
      border-top: none;
      background-clip: padding-box;
      box-shadow: inset 0px 0px 10px rgba(0, 255, 170, 0.15);
      animation: spin 2s infinite linear;
    }
  }

  .loader-content--circle2 {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: rotate(105deg);

    .loader-content--circleInner {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 5px solid rgba(0, 255, 170, 0.7);
      border-right: none;
      border-top: none;
      background-clip: padding-box;
      box-shadow: inset 0px 0px 10px rgba(0, 255, 170, 0.15);
      animation: spin 2s infinite linear;
    }
  }

  .loader-content--circle3 {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: rotate(140deg);

    .loader-content--circleInner {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 5px solid rgba(0, 255, 170, 0.7);
      border-right: none;
      border-top: none;
      background-clip: padding-box;
      box-shadow: inset 0px 0px 10px rgba(0, 255, 170, 0.15);
      animation: spin 2s infinite linear;
    }
  }

  .loader-content--circle4 {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: rotate(175deg);

    .loader-content--circleInner {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 5px solid rgba(0, 255, 170, 0.7);
      border-right: none;
      border-top: none;
      background-clip: padding-box;
      box-shadow: inset 0px 0px 10px rgba(0, 255, 170, 0.15);
      animation: spin 2s infinite linear;
    }
  }
`;
