import styled from 'styled-components';
import { styleHelpers, media } from '@pik/pik-ui';

const S = {};

S.Container = styled.a`
  display: flex;
  white-space: nowrap;
  font-size: 20px;
  line-height: 24px;
  transition: color .2s ease;
  color: #000;

  ${({ isActive }) => isActive && `
    cursor: default;
    font-weight: 600;
  `};

  ${({ isActive }) => !isActive && `
    &:hover, &:active {
      color: ${styleHelpers.colors.primary};
    }
  `};

  ${({ href }) => !href.length && `
    pointer-events: none;
    color: ${styleHelpers.colors.grayExtraLight};
  `};
`;

S.Text = styled.div`

`;


export default S;
