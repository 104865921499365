import { actionTypes } from './actions';

const initialState = {
  newsCommentsList: [],

  getNewsCommentsRequest: false,
  getNewsCommentsSuccess: false,
  getNewsCommentsError: false,

  createCommentText: '',
  createNewsCommentRequest: false,
  createNewsCommentSuccess: false,
  createNewsCommentError: false,

  updateComment: null,
  updateCommentText: '',
  updateNewsCommentRequest: false,
  updateNewsCommentSuccess: false,
  updateNewsCommentError: false,

  deleteNewsCommentRequest: false,
  deleteNewsCommentSuccess: false,
  deleteNewsCommentError: false,
};

const handleGetNewsCommentsRequest = (state) => ({
  ...state,
  getNewsCommentsRequest: true,
  getNewsCommentsSuccess: false,
  getNewsCommentsError: false,
});

const handleGetNewsCommentsSuccess = (state, action) => ({
  ...state,
  getNewsCommentsRequest: false,
  getNewsCommentsSuccess: true,
  getNewsCommentsError: false,
  newsCommentsList: action.newsComments,
});

const handleGetNewsCommentsError = (state) => ({
  ...state,
  getNewsCommentsRequest: false,
  getNewsCommentsSuccess: false,
  getNewsCommentsError: true,
});

const handleSetCreateCommentText = (state, action) => ({
  ...state,
  createCommentText: action.params.createCommentText,
});

const handleCreateNewsCommentRequest = (state) => ({
  ...state,
  createNewsCommentRequest: true,
  createNewsCommentSuccess: false,
  createNewsCommentError: false,
});

const handleCreateNewsCommentSuccess = (state, action) => ({
  ...state,
  createNewsCommentRequest: false,
  createNewsCommentSuccess: true,
  createNewsCommentError: false,
});

const handleCreateNewsCommentError = (state) => ({
  ...state,
  createNewsCommentRequest: false,
  createNewsCommentSuccess: false,
  createNewsCommentError: true,
});

const handleSetReplyCommentParent = (state, action) => ({
  ...state,
  replyCommentParent: action.params.replyCommentParent,
});

const handleSetReplyCommentText = (state, action) => ({
  ...state,
  replyCommentText: action.params.replyCommentText,
});

const handleSetUpdateComment = (state, action) => ({
  ...state,
  updateComment: action.params.updateComment,
});

const handleSetUpdateCommentText = (state, action) => ({
  ...state,
  updateCommentText: action.params.updateCommentText,
});

const handleUpdateNewsCommentRequest = (state) => ({
  ...state,
  updateNewsCommentRequest: true,
  updateNewsCommentSuccess: false,
  updateNewsCommentError: false,
});

const handleUpdateNewsCommentSuccess = (state, action) => ({
  ...state,
  updateNewsCommentRequest: false,
  updateNewsCommentSuccess: true,
  updateNewsCommentError: false,
});

const handleUpdateNewsCommentError = (state) => ({
  ...state,
  updateNewsCommentRequest: false,
  updateNewsCommentSuccess: false,
  updateNewsCommentError: true,
});


const handleDeleteNewsCommentRequest = (state) => ({
  ...state,
  deleteNewsCommentRequest: true,
  deleteNewsCommentSuccess: false,
  deleteNewsCommentError: false,
});

const handleDeleteNewsCommentSuccess = (state, action) => ({
  ...state,
  deleteNewsCommentRequest: false,
  deleteNewsCommentSuccess: true,
  deleteNewsCommentError: false,
});

const handleDeleteNewsCommentError = (state) => ({
  ...state,
  deleteNewsCommentRequest: false,
  deleteNewsCommentSuccess: false,
  deleteNewsCommentError: true,
});

const handleAddNewsComment = (state, action) => {
  const newsComment = action.params.newsComment;

  const newsCommentsList = [...state.newsCommentsList];

  if (newsComment.replyCommentId) {
    const foundIndex = state.newsCommentsList.findIndex((_newsComment) => _newsComment.id === newsComment.replyCommentId);

    if (newsCommentsList[foundIndex].replies) {
      newsCommentsList[foundIndex].replies.unshift(newsComment);
    } else {
      newsCommentsList[foundIndex].replies = [newsComment];
    }
  } else {
    newsCommentsList.unshift(newsComment);
  }

  return {
    ...state,
    newsCommentsList,
  };
};

const handleRemoveNewsComment = (state, action) => {
  const newsCommentId = action.params.newsCommentId;

  const foundIndex = state.newsCommentsList.findIndex((newsComment) => newsComment.id === newsCommentId);

  const newsCommentsList = [...state.newsCommentsList];

  if (foundIndex > -1) {
    newsCommentsList.splice(foundIndex, 1);
  } else {
    // Try find comment data in comments replies;

    let reply;
    let replyFoundIndex;
    let parentComment;
    let parentCommentFoundIndex;
    let repliesList;

    state.newsCommentsList.forEach((newsComment, i) => {
      if (reply) return;

      if (newsComment.replies) {
        newsComment.replies.forEach((_newsComment, _i) => {
          if (reply) return;

          if (_newsComment.id === newsCommentId) {
            reply = _newsComment;
            replyFoundIndex = _i
          }
        })

        if (reply) {
          parentCommentFoundIndex = i;

          parentComment = {...newsComment};
          repliesList= [...newsComment.replies];
        }
      }
    });

    repliesList.splice(replyFoundIndex, 1);

    parentComment.replies = repliesList;

    newsCommentsList.splice(parentCommentFoundIndex, 1, parentComment);
  }

  return {
    ...state,
    newsCommentsList,
  };
};

const handleChangeNewsComment = (state, action) => {
  console.log('handleChangeNewsComment', action)
  const newsCommentId = action.params.newsComment.id;

  const newsCommentsList = [...state.newsCommentsList];

  let foundIndex = state.newsCommentsList.findIndex((newsComment) => newsComment.id === newsCommentId);

  if (foundIndex > -1) {
    newsCommentsList.splice(foundIndex, 1, action.params.newsComment);
  } else {
    console.log('trye find reply');
    // Try find comment data in comments replies;

    let reply;
    let replyFoundIndex;
    let parentComment;
    let parentCommentFoundIndex;
    let repliesList;

    state.newsCommentsList.forEach((newsComment, i) => {
      if (reply) return;

      if (newsComment.replies) {
        newsComment.replies.forEach((_newsComment, _i) => {
          if (reply) return;

          if (_newsComment.id === newsCommentId) {
            reply = _newsComment;
            replyFoundIndex = _i
          }
        })

        if (reply) {
          parentCommentFoundIndex = i;
          // debugger;
          parentComment = {...newsComment};
          repliesList= [...newsComment.replies];
        }
      }
    });

    repliesList.splice(replyFoundIndex, 1, action.params.newsComment);

    parentComment.replies = repliesList;

    newsCommentsList.splice(parentCommentFoundIndex, 1, parentComment);
  }

  return {
    ...state,
    newsCommentsList,
  };
};

const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_NEWS_COMMENTS]: handleResetState,

    [actionTypes.GET_NEWS_COMMENTS_REQUEST]: handleGetNewsCommentsRequest,
    [actionTypes.GET_NEWS_COMMENTS_SUCCESS]: handleGetNewsCommentsSuccess,
    [actionTypes.GET_NEWS_COMMENTS_ERROR]: handleGetNewsCommentsError,

    [actionTypes.SET_CREATE_COMMENT_TEXT]: handleSetCreateCommentText,
    [actionTypes.CREATE_NEWS_COMMENT_REQUEST]: handleCreateNewsCommentRequest,
    [actionTypes.CREATE_NEWS_COMMENT_SUCCESS]: handleCreateNewsCommentSuccess,
    [actionTypes.CREATE_NEWS_COMMENT_ERROR]: handleCreateNewsCommentError,

    [actionTypes.SET_REPLY_COMMENT_PARENT]: handleSetReplyCommentParent,
    [actionTypes.SET_REPLY_COMMENT_TEXT]: handleSetReplyCommentText,

    [actionTypes.SET_UPDATE_COMMENT]: handleSetUpdateComment,
    [actionTypes.SET_UPDATE_COMMENT_TEXT]: handleSetUpdateCommentText,
    [actionTypes.UPDATE_NEWS_COMMENT_REQUEST]: handleUpdateNewsCommentRequest,
    [actionTypes.UPDATE_NEWS_COMMENT_SUCCESS]: handleUpdateNewsCommentSuccess,
    [actionTypes.UPDATE_NEWS_COMMENT_ERROR]: handleUpdateNewsCommentError,

    [actionTypes.DELETE_NEWS_COMMENT_REQUEST]: handleDeleteNewsCommentRequest,
    [actionTypes.DELETE_NEWS_COMMENT_SUCCESS]: handleDeleteNewsCommentSuccess,
    [actionTypes.DELETE_NEWS_COMMENT_ERROR]: handleDeleteNewsCommentError,

    [actionTypes.ADD_NEWS_COMMENT]: handleAddNewsComment,
    [actionTypes.REMOVE_NEWS_COMMENT]: handleRemoveNewsComment,
    [actionTypes.CHANGE_NEWS_COMMENT]: handleChangeNewsComment,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
