import { actionTypes } from './actions';

const initialState = {
  newsCommentsList: [],

  getNewsCommentsRequest: false,
  getNewsCommentsSuccess: false,
  getNewsCommentsError: false,
};

const handleGetNewsCommentsRequest = (state) => ({
  ...state,
  getNewsCommentsRequest: true,
  getNewsCommentsSuccess: false,
  getNewsCommentsError: false,
});

const handleGetNewsCommentsSuccess = (state, action) => ({
  ...state,
  getNewsCommentsRequest: false,
  getNewsCommentsSuccess: true,
  getNewsCommentsError: false,
  newsCommentsList: action.newsComments,
});

const handleGetNewsCommentsError = (state) => ({
  ...state,
  getNewsCommentsRequest: false,
  getNewsCommentsSuccess: false,
  getNewsCommentsError: true,
});

const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_NEWS_COMMENTS_PUBLIC]: handleResetState,

    [actionTypes.GET_NEWS_COMMENTS_PUBLIC_REQUEST]: handleGetNewsCommentsRequest,
    [actionTypes.GET_NEWS_COMMENTS_PUBLIC_SUCCESS]: handleGetNewsCommentsSuccess,
    [actionTypes.GET_NEWS_COMMENTS_PUBLIC_ERROR]: handleGetNewsCommentsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
