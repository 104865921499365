export const actionTypes = {
  GET_PROJECTS_NEWS_ARTICLE: 'GET_PROJECTS_NEWS_ARTICLE',
  GET_PROJECTS_NEWS_ARTICLE_REQUEST: 'GET_PROJECTS_NEWS_ARTICLE_REQUEST',
  GET_PROJECTS_NEWS_ARTICLE_SUCCESS: 'GET_PROJECTS_NEWS_ARTICLE_SUCCESS',
  GET_PROJECTS_NEWS_ARTICLE_ERROR: 'GET_PROJECTS_NEWS_ARTICLE_ERROR',

  UPDATE_PROJECTS_NEWS_ARTICLE: 'UPDATE_PROJECTS_NEWS_ARTICLE_PAGE',

  RESET_STATE_PROJECTS_NEWS_ARTICLE: 'RESET_STATE_PROJECTS_NEWS_ARTICLE',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_PROJECTS_NEWS_ARTICLE });


export const getNewsArticle = (id) => ({ type: actionTypes.GET_PROJECTS_NEWS_ARTICLE, id });

export const getNewsArticleRequest = () => ({ type: actionTypes.GET_PROJECTS_NEWS_ARTICLE_REQUEST });

export const getNewsArticleSuccess = (data) => ({ type: actionTypes.GET_PROJECTS_NEWS_ARTICLE_SUCCESS, data });

export const getNewsArticleError = (error) => ({ type: actionTypes.GET_PROJECTS_NEWS_ARTICLE_ERROR, error });

export const updateNewsArticle = (params) => ({ type: actionTypes.UPDATE_PROJECTS_NEWS_ARTICLE, ...params });
