import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetDocs,
} from './api';

import {
  actionTypes,
  getDocsRequest,
  getDocsSuccess,
  getDocsError,
} from './actions';


function* getDocsSaga(data) {
  try {
    yield put(getDocsRequest());

    const { user } = yield select(({ userService }) => userService);

    const { data: { data: docs } } = yield call(() => apiGetDocs({
      usersUnitId: user.unitId,
      currentUserGlobalId: user.globalId,
    }));

    yield put(getDocsSuccess({
      docs,
    }));
  } catch (error) {
    console.log('GET MY_TEAM_LATEST DOCS ERROR', error);
    yield put(getDocsError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_MY_TEAM_LATEST_DOCS, getDocsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
