export const actionTypes = {
  GET_DOCS_FAVOURITES: 'GET_DOCS_FAVOURITES',
  GET_DOCS_FAVOURITES_REQUEST: 'GET_DOCS_FAVOURITES_REQUEST',
  GET_DOCS_FAVOURITES_SUCCESS: 'GET_DOCS_FAVOURITES_SUCCESS',
  GET_DOCS_FAVOURITES_ERROR: 'GET_DOCS_FAVOURITES_ERROR',

  RESET_STATE_DOCS_FAVOURITES: 'RESET_STATE_DOCS_FAVOURITES',
};

export const resetStateDocs = () => ({ type: actionTypes.RESET_STATE_DOCS_FAVOURITES });


export const getDocs = (params) => ({ type: actionTypes.GET_DOCS_FAVOURITES, params });

export const getDocsRequest = () => ({ type: actionTypes.GET_DOCS_FAVOURITES_REQUEST });

export const getDocsSuccess = (params) => ({ type: actionTypes.GET_DOCS_FAVOURITES_SUCCESS, ...params });

export const getDocsError = (error) => ({ type: actionTypes.GET_DOCS_FAVOURITES_ERROR, error });
