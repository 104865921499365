import React, { useState, useEffect } from "react";
import NextLink from "next/link";

import WebsocketStore from "../../../stores/websocket";

import localIcons from "./icons";

import S from "./styles";

interface IProps {
  index: number;
  data: {
    id: string;
    type: string;
    url: string;
    text: string;
  };
}

function NotificationsPopup(props: IProps) {
  const { index, data } = props;

  const { deleteNotification } = WebsocketStore;

  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsDeleted(true);
    }, 10000);
  }, []);

  useEffect(() => {
    if (isDeleted) {
      setTimeout(() => {
        deleteNotification(data.id);
      }, 350);
    }
  }, [isDeleted]);

  return (
    <NextLink href={data.url}>
      <S.Container index={index} isDeleted={isDeleted}>
        <audio
          autoPlay
          src={
            "https://24108.selcdn.ru/lkdp/files/2023-09-15_06-55/melody-eaf5009c-a2e5-4cf2-95cf-5fd16449a2c1.mp3"
          }
        />

        <S.Header>
          {data.type}
          <S.Controls>
            <S.ControlsItem window>
              <a
                href={data.url}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                {localIcons.window}
              </a>
            </S.ControlsItem>

            <S.ControlsItem
              onClick={(e) => {
                e.preventDefault();
                setIsDeleted(true);
              }}
            >
              {localIcons.close}
            </S.ControlsItem>
          </S.Controls>
        </S.Header>
        {data.text}
      </S.Container>
    </NextLink>
  );
}

export default NotificationsPopup;
