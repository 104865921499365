import {
  createStore,
  applyMiddleware,
  combineReducers,
} from 'redux';

import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import app from './app';
import login from './login';
import errorPopup from './errorPopup';
import notifications from './notifications';
import sideNav from './sideNav';
import search from './search';
import news from './news';
import newsArticle from './newsArticle';
import newsArticlePublic from './newsArticlePublic';
import newsArticleUsersReads from './newsArticleUsersReads';
import newsComments from './newsComments';
import newsCommentsPublic from './newsCommentsPublic';
import projectsNews from './projectsNews';
import projectsNewsCategories from './projectsNewsCategories';
import projectsNewsArticle from './projectsNewsArticle';
import projectsNewsCategory from './projectsNewsCategory';
import cultureDocs from './cultureDocs';
import myTeamLatestDocs from './myTeamLatestDocs';
import docsTeamIndex from './docsTeamIndex';
import teamDocsFolderIndex from './teamDocsFolderIndex';
import docsFolders from './docsFolders';
import teamDocsWithoutFolder from './teamDocsWithoutFolder';
import doc from './doc';
import docsSearch from './docsSearch';
import user from './user';
import usersUnits from './usersUnits';
import projectsNewsSearch from './projectsNewsSearch';
import favourites from './favourites';
import iSpringUsers from './iSpringUsers';
import iSpringCourses from './iSpringCourses';
import iSpringUserResults from './iSpringUserResults';
import ai from './ai';

const rootReducer = combineReducers({
  appService: app.reducer,
  sideNavService: sideNav.reducer,
  notificationsService: notifications.reducer,
  searchService: search.reducer,
  userService: user.reducer,
  usersUnitsService: usersUnits.reducer,
  loginService: login.reducer,
  errorPopupService: errorPopup.reducer,
  newsService: news.reducer,
  newsArticleService: newsArticle.reducer,
  newsArticlePublicService: newsArticlePublic.reducer,
  newsArticleUsersReadsService: newsArticleUsersReads.reducer,
  newsCommentsService: newsComments.reducer,
  newsCommentsPublicService: newsCommentsPublic.reducer,
  projectsNewsService: projectsNews.reducer,
  projectsNewsCategoriesService: projectsNewsCategories.reducer,
  projectsNewsArticleService: projectsNewsArticle.reducer,
  projectsNewsCategoryService: projectsNewsCategory.reducer,
  cultureDocsService: cultureDocs.reducer,
  myTeamLatestDocsService: myTeamLatestDocs.reducer,
  docsTeamIndexService: docsTeamIndex.reducer,
  docService: doc.reducer,
  docsSearchService: docsSearch.reducer,
  projectsNewsSearchService: projectsNewsSearch.reducer,
  favouritesService: favourites.reducer,
  docsFoldersService: docsFolders.reducer,
  teamDocsWithoutFolderService: teamDocsWithoutFolder.reducer,
  teamDocsFolderIndexService: teamDocsFolderIndex.reducer,
  iSpringUsersService: iSpringUsers.reducer,
  iSpringCoursesService: iSpringCourses.reducer,
  iSpringUserResultsService: iSpringUserResults.reducer,
  aiService: ai.reducer,
});

function* rootSaga() {
  yield all([
    user.sagas(),
    usersUnits.sagas(),
    login.sagas(),
    notifications.sagas(),
    errorPopup.sagas(),
    news.sagas(),
    newsArticle.sagas(),
    newsArticlePublic.sagas(),
    newsArticleUsersReads.sagas(),
    newsComments.sagas(),
    newsCommentsPublic.sagas(),
    projectsNews.sagas(),
    projectsNewsCategories.sagas(),
    projectsNewsArticle.sagas(),
    projectsNewsCategory.sagas(),
    docsTeamIndex.sagas(),
    cultureDocs.sagas(),
    myTeamLatestDocs.sagas(),
    doc.sagas(),
    docsSearch.sagas(),
    projectsNewsSearch.sagas(),
    favourites.sagas(),
    docsFolders.sagas(),
    teamDocsWithoutFolder.sagas(),
    teamDocsFolderIndex.sagas(),
    iSpringUsers.sagas(),
    iSpringCourses.sagas(),
    iSpringUserResults.sagas(),
    ai.sagas(),
  ]);
}

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    initialState,
    bindMiddleware([sagaMiddleware]),
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;
