import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiSearchDocs,
} from './api';

import {
  actionTypes,

  searchDocsRequest,
  searchDocsSuccess,
  searchDocsError,
} from './actions';

function* searchDocsSaga(data) {
  try {
    yield put(searchDocsRequest());

    const params = {
      search: data.search,
      searchType: data.searchType,
      isPublished: true,
      exactReq: data.exactReq,
    };

    if (data.usersUnitId) {
      params.usersUnitId = data.usersUnitId;
    }

    const {
      data: {
        docs,
        success,
      },
    } = yield call(() => apiSearchDocs(params));

    if (success) {
      yield put(searchDocsSuccess({
        searchScope: data.searchScope,
        isFolderSearch: data.isFolderSearch,
        docs,
      }));
    } else {
      throw Error({
        success: false,
      });
    }
  } catch (error) {
    console.log('SEARCH DOCS ERROR', error);
    yield put(searchDocsError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.SEARCH_DOCS, searchDocsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
