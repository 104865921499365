import { makeAutoObservable } from "mobx";
import axios from "axios";

import apiUrls from "../common/apiServices";

import UserStore from "./user";

class AccountStore {
  task = null;
  teams = null;
  executorTeams = null;
  executorsChats = null;
  firtsRender = true;
  historyData = null;
  selectedTab = 0;
  selectedTabStatistics = 0;
  pagiNum = 0;
  isLoadingHistory = false;
  initTasks = null;
  tasks = null;
  tasksInTub = null;
  team = null;
  searchFieldText = "";
  history = null;
  isOpenClosedTasks = false;
  isOpenClosedTasksScripts = false;
  isOpenClosedTasksAdmins = false;
  loadingComments = false;
  commentsData = null;
  tabs = [
    {
      title: "Я автор",
      value: 0,
      open: [],
      closed: [],
    },

    {
      title: "Я согласующий",
      value: 0,
      open: [],
      closed: [],
    },

    {
      title: "Я исполнитель",
      value: 0,
      open: [],
      closed: [],
    },

    {
      title: "Я наблюдатель",
      value: 0,
      open: [],
      closed: [],
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  setTask = (payload) => (this.task = payload);

  setLoadingComments = (payload) => (this.loadingComments = payload);

  setCommentsData = (payload) => (this.commentsData = payload);

  getComments = () => {
    if (!this.commentsData) this.setLoadingComments(true);

    const taskId = window.location.pathname.split("/").reverse()[0];
    axios({
      method: "GET",
      url: apiUrls.clientApi.usersTasksComments.list,
      params: { taskId },
    })
      .then((response) => {
        this.setCommentsData(response.data.data);
        this.setLoadingComments(false);
      })
      .catch((err) => console.log(err));
  };

  updateUserHistory = () => {
    const user = { ...UserStore.user };
    user.history = {
      ...user.history,
      account: {
        mainPage: {
          pagiNum: this.pagiNum,
          tabsNum: this.selectedTab,
          isOpenClosedTasks: this.isOpenClosedTasks,
        },
        managersPage: {
          isOpenClosedTasksScripts: this.isOpenClosedTasksScripts,
          isOpenClosedTasksAdmins: this.isOpenClosedTasksAdmins,
          pagiNum: this.history.managersPage.pagiNum,
        },
        statisticsPage: {
          selectedTab: this.selectedTabStatistics,
        },
      },
    };

    UserStore.updateUserAction(user);
  };

  setSelectedTabStatistics = (num) => {
    this.selectedTabStatistics = num;
    this.updateUserHistory();
  };

  setIsOpenClosedTasksAction = (status) => {
    this.isOpenClosedTasks = status;
    this.updateUserHistory();
  };

  setIsOpenClosedTasksScriptsAction = (status) => {
    this.isOpenClosedTasksScripts = status;
    this.updateUserHistory();
  };

  setIsOpenClosedTasksAdminsAction = (status) => {
    this.isOpenClosedTasksAdmins = status;
    this.updateUserHistory();
  };

  setPagiNumOpenedScriptsTasksManagerPage = (pagiNum) => {
    this.history.managersPage.pagiNum.opened.scripts = pagiNum;
    this.updateUserHistory();
  };

  setPagiNumOpenedAdminsTasksManagerPage = (pagiNum) => {
    this.history.managersPage.pagiNum.opened.admins = pagiNum;
    this.updateUserHistory();
  };

  setPagiNumClosedScriptsTasksManagerPage = (pagiNum) => {
    this.history.managersPage.pagiNum.closed.scripts = pagiNum;
    this.updateUserHistory();
  };

  setPagiNumClosedAdminsTasksManagerPage = (pagiNum) => {
    this.history.managersPage.pagiNum.closed.admins = pagiNum;
    this.updateUserHistory();
  };

  setSearchFieldText = (text, managersPage?) => {
    this.searchFieldText = text;
    this.sortBySearch(text);

    if (managersPage) {
      if (!!text !== this.isOpenClosedTasksScripts) {
        this.isOpenClosedTasksScripts = !!text;
        this.isOpenClosedTasksAdmins = !!text;
      }
    } else {
      if (!!text !== this.isOpenClosedTasks) {
        this.isOpenClosedTasks = !!text;
      }
    }

    if (!this.firtsRender) {
      this.updateUserHistory();
    } else {
      this.firtsRender = false;
    }
  };

  setSelectedTab = (tabNum) => {
    this.selectedTab = tabNum;
    this.pagiNum = 0;
    this.initTasksInTub();
    this.sortBySearch(this.searchFieldText);
    this.updateUserHistory();
  };

  setSelectedPagiNum = (pagiNum) => {
    this.pagiNum = pagiNum;
    this.updateUserHistory();
  };

  checkIsMatching = (task) => {
    if (!this.team) this.initTeam();

    const matchingArr = task.matching?.split(", ");
    return !!matchingArr?.find((el) => el === this.team);
  };

  initHistory = (payload) => {
    const account = payload.account;
    this.history = account;
    this.selectedTab = account.mainPage.tabsNum;
    this.pagiNum = account.mainPage.pagiNum;
    this.isOpenClosedTasks = account.mainPage.isOpenClosedTasks;
    this.isOpenClosedTasksScripts =
      account.managersPage.isOpenClosedTasksScripts;
    this.isOpenClosedTasksAdmins = account.managersPage.isOpenClosedTasksAdmins;
    this.selectedTabStatistics = account.statisticsPage.selectedTab;
  };

  checkTasks = () => {
    const arr = [
      {
        title: "Я автор",
        value: 0,
        open: [],
        closed: [],
      },

      {
        title: "Я согласующий",
        value: 0,
        open: [],
        closed: [],
      },

      {
        title: "Я исполнитель",
        value: 0,
        open: [],
        closed: [],
      },

      {
        title: "Я наблюдатель",
        value: 0,
        open: [],
        closed: [],
      },
    ];

    this.tasks?.forEach((task) => {
      if (task.name === UserStore.user.fullName) {
        if (task.status === "Закрытая") {
          arr[0].closed.push(task);
        } else {
          arr[0].open.push(task);
          arr[0].value += 1;
        }
      }

      if (task.matching) {
        const matchingArr = task.matching.split(", ");
        if (matchingArr.find((el) => el === this.team)) {
          if (task.status === "Закрытая") {
            arr[1].closed.push(task);
          } else {
            arr[1].open.push(task);
            arr[1].value += 1;
          }
        }
      }

      if (this.team === task.executor) {
        if (task.status === "Закрытая") {
          arr[2].closed.push(task);
        } else {
          arr[2].open.push(task);
          arr[2].value += 1;
        }
      }

      if (task.observers) {
        const name = UserStore.user.fullName
          .split(" ")
          ?.slice(0, -1)
          ?.join(" ");
        const finded = task.observers?.split(", ")?.some((obs) => obs === name);
        if (!finded) return;

        if (task.status === "Закрытая") {
          arr[3].closed.push(task);
        } else {
          arr[3].open.push(task);
          arr[3].value += 1;
        }
      }
    });

    this.tabs = arr;
    this.initTasksInTub();
  };

  initTasksInTub = () => (this.tasksInTub = this.tabs[this.selectedTab]);

  initTeam = () => {
    const globalId = UserStore.user.globalId;

    for (let i = 0; i < this.teams?.length; i++) {
      const users = this.teams[i].users.list;
      for (let k = 0; k < users.length; k++) {
        if (users[k].globalId === globalId) {
          this.team = this.teams[i].title;
          break;
        }
      }
    }
  };

  initExecutorTeams = () => {
    const res = [];
    this.teams.forEach((team) => {
      if (team.title === "Скриптологи" || team.title === "Администраторы БЗ") {
        team.chats = team.users.list.map((user) => user.chatId);
        res.push(team);
      }
    });

    this.executorTeams = res;
  };

  getTeams = () => {
    axios({
      method: "GET",
      url: apiUrls.clientApi.teams.list,
    })
      .then((res) => {
        this.teams = res.data.data;
        this.initExecutorTeams();
        this.getTasks();
      })
      .catch((err) => console.log(err));
  };

  getTasks = () => {
    axios({
      method: "GET",
      url: apiUrls.clientApi.usersTasks.list,
    }).then((res) => {
      const result = res.data.data.sort(
        (a, b) =>
          new Date(b.creatingDate).getTime() -
          new Date(a.creatingDate).getTime()
      );
      this.initTasks = result;
      this.tasks = result;

      if (!this.team) this.initTeam();

      this.checkTasks();
    });
  };

  getHistory = (taskId: string) => {
    this.isLoadingHistory = true;

    axios({
      method: "GET",
      url: apiUrls.clientApi.usersTasksHistory.item(taskId),
      params: { taskId },
    })
      .then((res) => {
        const response = res.data.data;
        const sorterredData = response.sort((a, b) => +b.id - +a.id);
        this.historyData = sorterredData;
        this.isLoadingHistory = false;
      })
      .catch((err) => {
        console.log(err);
        this.isLoadingHistory = false;
      });
  };

  sortBySearch = (payload) => {
    if (!payload) {
      this.tasks = this.initTasksInTub();
      return;
    }

    const tasksData = { ...this.tabs[this.selectedTab] };

    tasksData.open = tasksData.open.filter((item) =>
      item.title.toUpperCase().includes(payload.toUpperCase())
    );

    tasksData.closed = tasksData.closed.filter((item) =>
      item.title.toUpperCase().includes(payload.toUpperCase())
    );

    this.tasksInTub = tasksData;
  };

  getTasksInTab = () => this.tabs[this.selectedTab];
}

export default new AccountStore();
