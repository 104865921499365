import axios from 'axios';
import apiUrls from '../../common/apiServices';

export const apiGetDocs = (params) => axios({
  method: 'GET',
  url: apiUrls.clientApi.docs.listTeam(params.usersUnitId),
  params: {
    currentUserGlobalId: params.currentUserGlobalId,
    isPublished: true,
    limit: 4,
    orderBy: 'datePublished',
    orderByDirection: 'desc',
  },
});
