import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetUnreadNews,
} from './api';

import {
  actionTypes,
  getUnreadNewsRequest,
  getUnreadNewsSuccess,
  getUnreadNewsError,
} from './actions';


function* getUnreadNewsSaga(data) {
  try {
    yield put(getUnreadNewsRequest());

    const { user } = yield select(({ userService }) => userService);

    const { data: { data: news } } = yield call(() => apiGetUnreadNews({
      currentUserGlobalId: user.globalId,
    }));

    yield put(getUnreadNewsSuccess({
      news,
    }));
  } catch (error) {
    console.log('NOTIFICATIONS: GET UNREAD NEWS ERROR', error);

    yield put(getUnreadNewsError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_UNREAD_NEWS, getUnreadNewsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
