import convert from 'xml-js';

import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetUsers,
} from './api';

import {
  actionTypes,
  getUsersRequest,
  getUsersSuccess,
  getUsersError,
} from './actions';

function* getUsersSaga() {
  try {
    yield put(getUsersRequest());

    const response = yield call(() => apiGetUsers());

    const xmlData = response.data;

    const convertedData = convert.xml2js(xmlData, { compact: true });

    const users = convertedData.response.userProfile;

    yield put(getUsersSuccess({
      users,
    }));
  } catch (error) {
    console.log('GET_ISPRING_USERS ERROR', error);
    yield put(getUsersError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_ISPRING_USERS, getUsersSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
