import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiSearchProjectsNews,
} from './api';

import {
  actionTypes,

  searchProjectsNewsRequest,
  searchProjectsNewsSuccess,
  searchProjectsNewsError,
} from './actions';


function* searchProjectsNewsSaga(data) {
  try {
    yield put(searchProjectsNewsRequest());

    let params = {
      search: data.search,
      searchType: data.searchType,
      isPublished: true,
    };

    const { data: {
      docs: projectsNews,
      success: success
    } } = yield call(() => apiSearchProjectsNews(params));

    if (success) {
      yield put(searchProjectsNewsSuccess(projectsNews));
    } else {
      throw Error({
        success: false
      });
    }
  } catch (error) {
    console.log('SEARCH PROJECTS_NEWS ERROR', error);
    yield put(searchProjectsNewsError(error));
  }
}


function* watchSagas() {
  yield takeEvery(actionTypes.SEARCH_PROJECTS_NEWS, searchProjectsNewsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
