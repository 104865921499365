import axios from 'axios';
import { parseCookies } from 'nookies';

import urls from './urls';
import apiServices from './apiServices';

const LOGIN_PATH = urls.login;
const LOGOUT_PATH = urls.logout;

const checkAuthorized = (tokenLkdp) => axios({
  url: apiServices.authApi.check,
  headers: {
    // Set cookies directly since because axios instance doesn't have context.
    Cookie: `${process.env.AUTH_TOKEN_LKDP_COOKIE_NAME}=${tokenLkdp};`,
  },
})
  .then((response) => response.status === 200)
  .catch(() => false);

const checkIsBlocked = (tokenLkdp) => axios({
  url: apiServices.clientApi.users.checkCurrentUserIsBlocked,
  headers: {
    // Set cookies directly since because axios instance doesn't have context.
    Cookie: `${process.env.AUTH_TOKEN_LKDP_COOKIE_NAME}=${tokenLkdp};`,
  },
})
  .then((response) => response.data)
  .catch((err) => err.response.data);

const redirect = (ctx, path) => {
  if (ctx.res) {
    ctx.res.writeHead(302, { Location: path });
    ctx.res.end();
  } else {
    window.location.href = path;
  }
};

const logout = (ctx = {}, callbackPath) => {
  if (callbackPath) {
    redirect(ctx, `${LOGOUT_PATH}?callbackPath=${callbackPath}`);
  } else {
    redirect(ctx, LOGOUT_PATH);
  }
};

const getTokenLkdp = (ctx) => parseCookies(ctx)[process.env.AUTH_TOKEN_LKDP_COOKIE_NAME];

const checkAuth = async (ctx, config, callbackPath) => {
  const tokenLkdp = getTokenLkdp(ctx);
  const { pathname, query } = ctx;

  if (pathname === LOGIN_PATH) {
    return false;
  }

  if (tokenLkdp) {
    const isAuthorizedCheck = await checkAuthorized(tokenLkdp);

    if (!isAuthorizedCheck) {
      logout(ctx, callbackPath);
      return false;
    }

    const isBlockedCheck = await checkIsBlocked(tokenLkdp);

    if (!isBlockedCheck || !isBlockedCheck.isFound || isBlockedCheck.isBlocked) {
      logout(ctx, callbackPath);
      return false;
    }

    return isAuthorizedCheck;
  }

  logout(ctx, callbackPath);
  return false;
};

export default {
  getTokenLkdp,
  checkAuth,
  logout,
  checkAuthorized,
  redirect,
  LOGIN_PATH,
};
