import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import axios from 'axios';

import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';

import styled from 'styled-components';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'emoji-mart/css/emoji-mart.css'

import ReactNotification from 'react-notifications-component';
import '../components/common/ReactNotification/scss/notification.scss';
import 'animate.css/animate.min.css';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { BaseStyles } from '@pik/pik-ui';
import GlobalStyles from '../common/styles/GlobalStyles';
import ResponsiveContainer from '../components/common/ResponsiveContainer';
import { colors } from '../common/styles/const';

import auth from '../common/auth';
import apiServices from '../common/apiServices';

import PrivateLayout from '../components/common/PrivateLayout';

import makeStore from '../services/store';

import { initGoogleAnalytics } from '../common/ga';

import 'react-circular-progressbar/dist/styles.css';

const { checkAuth, logout } = auth;

dayjs.locale('ru');
dayjs.extend(customParseFormat);

export const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background: #F6F5F5;
`;

export const ComponentWrapper = styled.div`

`;

const handleRedirect = (ctx, redirect) => {
  const path = redirect;

  if (ctx.res) {
    ctx.res.writeHead(302, { Location: path });
    ctx.res.end();
  } else {
    Router.push(path);
  }
};


axios.interceptors.response.use(response => {
  return response;
}, (error) => {
  if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
    // 401 or 403 — Not Authorized
    // Covers the case when JWT token expired
    // while using app.

    logout({}, window.location.pathname);
  };

  return Promise.reject(error);
});


class PikLkdp extends App {
  constructor(props) {
    super(props);

    this.state = {
      isAuthorized: props.isAuthorized
    };
  }

  static async getInitialProps({ Component, ctx, store }) {
    const { redirect } = ctx.query;
    let props = {};

    let isAuthorized = false;


    if (ctx.res) {
      isAuthorized = await checkAuth(ctx, apiServices, ctx.req.originalUrl || ctx.pathname);
    }

    if (redirect) {
      handleRedirect(ctx, redirect);
    }

    if (Component.getInitialProps) {
      props = await Component.getInitialProps(ctx);
    }

    return {
      ...props,
      isAuthorized
    };
  }

  componentDidMount() {
    const isProd = process.env.NODE_ENV === 'production';

    Router.onRouteChangeStart = () => window.scrollTo(0, 0);

    if (process.env.GOOGLE_ANALYTICS_ID) {
      initGoogleAnalytics(process.env.GOOGLE_ANALYTICS_ID);
    }
  }

  render() {
    const { Component, store, router } = this.props;

    const isLogin = router.pathname.startsWith('/login');

    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
          <title>PIK.Team</title>
        </Head>
        <Provider store={store}>
          <ReactNotification />

          <Wrapper id="AppWrapper">
            {isLogin ?
              <Component />
            :
              <PrivateLayout isAuthorized={this.state.isAuthorized}>
                <Component isAuthorized={this.state.isAuthorized} />
              </PrivateLayout>
            }
          </Wrapper>
          <BaseStyles />
          <GlobalStyles />
          <ResponsiveContainer />
        </Provider>
      </>
    );
  }
}

export default withRedux(makeStore)(withReduxSaga(PikLkdp));
