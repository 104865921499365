import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { observer } from 'mobx-react-lite';

import TopNav from '../TopNav';
import SideNav from '../SideNav';
import Footer from '../Footer';
import PopupNewUsers from '../PopupNewUsers/index.tsx';
import NotificationsPopup from '../NotificationsPopup/index.tsx';

import S from './styles';

import UserStore from '../../../stores/user.tsx';
import WebsocketStore from '../../../stores/websocket.tsx';

import {
  getUsers,
  resetState as resetStateUsers,
} from '../../../services/iSpringUsers/actions';

import {
  getUserResults,
  resetState as resetStateUserResults,
} from '../../../services/iSpringUserResults/actions';

const PrivateLayout = observer(({ children, isAuthorized, user }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const { updateUserAction } = UserStore;

  const { notifications } = WebsocketStore;

  useEffect(() => {
    if (user) {
      const data = { ...user };
      const startingPoint = new Date('08/25/2023 12:00:00');
      const start = new Date(data.employmentStartDate);

      if (
        startingPoint.getTime() < start.getTime()
        && !data.popupNewUser.checkedPopup.status
      ) {
        setIsOpenPopup(true);

        const currentDate = new Date().toISOString().split('T')[0];

        data.popupNewUser.checkedPopup.status = true;
        data.popupNewUser.checkedPopup.date = currentDate;
        updateUserAction(data);
      }
    }
  }, [user]);

  return (
    <S.Container>
      <S.Wrapper>
        <S.TopNav>
          <TopNav isAuthorized={isAuthorized} />
        </S.TopNav>

        <SideNav isAuthorized={isAuthorized} />

        {isOpenPopup && <PopupNewUsers close={() => setIsOpenPopup(false)} />}

        <S.Content>{children}</S.Content>

        {notifications.map((el, i) => (
          <div key={el.id}>
            <NotificationsPopup data={el} index={i} />
          </div>
        ))}

        <Footer />
      </S.Wrapper>
    </S.Container>
  );
});

PrivateLayout.propTypes = {
  children: PropTypes.node,
};

PrivateLayout.defaultProps = {
  children: null,
};

const mapStateToProps = ({ userService }) => {
  const { user } = userService;

  return {
    user,
  };
};

const mapDispatchToProps = {
  getUsersAction: getUsers,
  resetStateUsersAction: resetStateUsers,

  getUserResultsAction: getUserResults,
  resetStateUserResultsAction: resetStateUserResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayout);
