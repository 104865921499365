export const actionTypes = {
  GET_CULTURE_DOCS: 'GET_CULTURE_DOCS',
  GET_CULTURE_DOCS_REQUEST: 'GET_CULTURE_DOCS_REQUEST',
  GET_CULTURE_DOCS_SUCCESS: 'GET_CULTURE_DOCS_SUCCESS',
  GET_CULTURE_DOCS_ERROR: 'GET_CULTURE_DOCS_ERROR',

  GET_MORE_CULTURE_DOCS: 'GET_MORE_CULTURE_DOCS',
  GET_MORE_CULTURE_DOCS_REQUEST: 'GET_MORE_CULTURE_DOCS_REQUEST',
  GET_MORE_CULTURE_DOCS_SUCCESS: 'GET_MORE_CULTURE_DOCS_SUCCESS',
  GET_MORE_CULTURE_DOCS_ERROR: 'GET_MORE_CULTURE_DOCS_ERROR',

  UPDATE_CULTURE_DOCS_ARTICLE: 'UPDATE_CULTURE_DOCS_ARTICLE',

  CHANGE_OFFSET_CULTURE_DOCS: 'CHANGE_OFFSET_CULTURE_DOCS',
  RESET_STATE_CULTURE_DOCS: 'RESET_STATE_CULTURE_DOCS',
};

export const changeOffset = (offset) => ({ type: actionTypes.CHANGE_OFFSET_CULTURE_DOCS, offset });

export const resetState = () => ({ type: actionTypes.RESET_STATE_CULTURE_DOCS });


export const getCultureDocs = (params) => ({ type: actionTypes.GET_CULTURE_DOCS, params });

export const getCultureDocsRequest = () => ({ type: actionTypes.GET_CULTURE_DOCS_REQUEST });

export const getCultureDocsSuccess = (params) => ({ type: actionTypes.GET_CULTURE_DOCS_SUCCESS, ...params });

export const getCultureDocsError = (error) => ({ type: actionTypes.GET_CULTURE_DOCS_ERROR, error });


// pagination
export const getMoreCultureDocs = (params) => ({ type: actionTypes.GET_MORE_CULTURE_DOCS, ...params });

export const getMoreCultureDocsRequest = () => ({ type: actionTypes.GET_MORE_CULTURE_DOCS_REQUEST });

export const getMoreCultureDocsSuccess = (params) => ({
  type: actionTypes.GET_MORE_CULTURE_DOCS_SUCCESS, ...params,
});

export const getMoreCultureDocsError = (error) => ({ type: actionTypes.GET_MORE_CULTURE_DOCS_ERROR, error });

export const updateCultureDocsArticle = (params) => ({ type: actionTypes.UPDATE_CULTURE_DOCS_ARTICLE, ...params });
