import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  getUnreadNews,
  resetState,
} from '../../../services/notifications/actions';

import {
  getUsers,
  resetState as resetStateUsers,
} from '../../../services/iSpringUsers/actions';

import {
  getCourses,
  resetState as resetStateCourses,
} from '../../../services/iSpringCourses/actions';

import {
  getUserResults,
  resetState as resetStateUserResults,
} from '../../../services/iSpringUserResults/actions';

import localIcons from './icons';

import urls from '../../../common/urls';

import S from './styles';

function Notifications(props) {
  const {
    user,
    unreadNewsList,
    getUnreadNewsRequest,
    getUnreadNewsAction,
    resetNotificationsStateAction,

    users,
    getUsersSuccess,
    getUsersRequest,

    courses,
    getCoursesSuccess,
    getCoursesRequest,

    userResults,
    getUserResultsRequest,
    getUserResultsSuccess,

    getUsersAction,
    getCoursesAction,
    getUserResultsAction,
  } = props;

  const [isShowPopup, setIsShowPopup] = useState(false);

  useEffect(() => {
    if (user) {
      getUnreadNewsAction();

      return resetNotificationsStateAction;
    }
  }, [getUnreadNewsAction, resetNotificationsStateAction, user]);

  useEffect(() => {
    if (!getUsersSuccess && !getUsersRequest) {
      getUsersAction();
    }
  }, [getUsersAction, getUsersSuccess]);

  useEffect(() => {
    if (!getCoursesSuccess && !getCoursesRequest) {
      getCoursesAction();
    }
  }, [getCoursesAction]);

  useEffect(() => {
    if (
      !getUserResultsSuccess
      && !getUserResultsSuccess
      && user
      && getUsersSuccess
      && users
      && users.length
    ) {
      // Find currently logged in user
      // in iSpring users by email.
      // Then get iSpring userId
      // to get iSpring user results.
      const iSpringUser = users.find((u) => {
        const fields = u.fields.field;
        const emailField = fields.find((f) => f.name._text === 'EMAIL');
        const emailValue = emailField.value._text;

        return emailValue === user.email;
      });

      if (iSpringUser) {
        getUserResultsAction({ userId: iSpringUser.userId._text });
      }
    }
  }, [user, users, getUsersSuccess]);

  let tests;
  let notStartedTests;

  if (getUserResultsSuccess && getCoursesSuccess) {
    const usedCoursesIds = userResults.map((uR) => uR.courseId._text);

    const usedCourses = courses.filter((item) => usedCoursesIds.indexOf(item.contentItemId._text) > -1);

    tests = usedCourses.filter((item) => item.type._text === 'Тест');

    tests.forEach((item) => {
      const id = item.contentItemId._text;
      const result = userResults.find((uR) => uR.courseId._text === id);

      item.result = result;
    });

    notStartedTests = tests.filter((item) => {
      if (item.result.completionStatus._text == 'not_started') {
        return true;
      } if (item.result.completionStatus._text == 'passed') {
        return false;
      } if (item.result.progress) {
        let progress = item.result.progress._text;

        progress = parseInt(progress, 10);

        return progress !== NaN && progress < 10;
      }
      return false;
    });

    notStartedTests = notStartedTests.map((_item) => {
      const item = {
        ..._item,
        type: 'test',
      };

      const textMaxLength = 65;

      if (item.title && item.title._text.length) {
        let text = item.title._text;

        if (text.length > textMaxLength) {
          text = `${text.substring(0, textMaxLength - 1)}…`;
        } else {
          text = text.substring(0, textMaxLength);
        }

        item.text = text;
      }

      return item;
    });
  }

  let coursesItems;
  let notStartedCourses;

  if (getUserResultsSuccess && getCoursesSuccess) {
    const usedCoursesIds = userResults.map((uR) => uR.courseId._text);

    const usedCourses = courses.filter((item) => usedCoursesIds.indexOf(item.contentItemId._text) > -1);

    coursesItems = usedCourses.filter((item) => item.type._text === 'Курс');

    coursesItems.forEach((item) => {
      const id = item.contentItemId._text;
      const result = userResults.find((uR) => uR.courseId._text === id);

      item.result = result;
    });

    notStartedCourses = coursesItems.filter((item) => {
      if (item.result.completionStatus._text == 'not_started') {
        return true;
      } if (item.result.completionStatus._text == 'passed') {
        return false;
      } if (item.result.progress) {
        let progress = item.result.progress._text;

        progress = parseInt(progress, 10);

        return progress !== NaN && progress < 10;
      }
      return false;
    });

    notStartedCourses = notStartedCourses.map((_item) => {
      const item = {
        ..._item,
        type: 'course',
      };

      const textMaxLength = 65;

      if (item.title && item.title._text.length) {
        let text = item.title._text;

        if (text.length > textMaxLength) {
          text = `${text.substring(0, textMaxLength - 1)}…`;
        } else {
          text = text.substring(0, textMaxLength);
        }

        item.text = text;
      }

      return item;
    });
  }

  let surveyItems;
  let notStartedSurveyItems;

  if (getUserResultsSuccess && getCoursesSuccess) {
    const usedCoursesIds = userResults.map((uR) => uR.courseId._text);

    const usedCourses = courses.filter((item) => usedCoursesIds.indexOf(item.contentItemId._text) > -1);

    surveyItems = usedCourses.filter((item) => item.type._text === 'Опрос');

    surveyItems.forEach((item) => {
      const id = item.contentItemId._text;
      const result = userResults.find((uR) => uR.courseId._text === id);

      item.result = result;
    });

    notStartedSurveyItems = surveyItems.filter((item) => {
      if (item.result.completionStatus._text == 'not_started') {
        return true;
      } if (item.result.completionStatus._text == 'passed') {
        return false;
      } if (item.result.progress) {
        let progress = item.result.progress._text;

        progress = parseInt(progress, 10);

        return progress !== NaN && progress < 10;
      }
      return false;
    });

    notStartedSurveyItems = notStartedSurveyItems.map((_item) => {
      const item = {
        ..._item,
        type: 'survey',
      };

      const textMaxLength = 65;

      if (item.title && item.title._text.length) {
        let text = item.title._text;

        if (text.length > textMaxLength) {
          text = `${text.substring(0, textMaxLength - 1)}…`;
        } else {
          text = text.substring(0, textMaxLength);
        }

        item.text = text;
      }

      return item;
    });
  }

  // console.log('Notifications unreadNewsList', unreadNewsList);
  // console.log('Notifications notStartedTests', notStartedTests);
  // console.log('Notifications notStartedCourses', notStartedCourses);
  // console.log('Notifications notStartedSurveyItems', notStartedSurveyItems);

  let items = unreadNewsList.map((newsItem) => {
    const item = {
      ...newsItem,
      type: 'news',
    };

    const textMaxLength = 65;

    if (newsItem.text && newsItem.text.length) {
      let { text } = newsItem;

      if (text.length > textMaxLength) {
        text = `${text.substring(0, textMaxLength - 1)}…`;
      } else {
        text = text.substring(0, textMaxLength);
      }

      item.text = text;
    }

    return item;
  });

  if (notStartedTests) {
    items = items.concat(notStartedTests);
  }

  if (notStartedCourses) {
    items = items.concat(notStartedCourses);
  }

  if (notStartedSurveyItems) {
    items = items.concat(notStartedSurveyItems);
  }

  let hasNotifications = false;

  if (items.length) {
    let date5DaysAgo = new Date(new Date().setDate(new Date().getDate() - 5));

    date5DaysAgo = date5DaysAgo.toISOString();

    items = items.filter((item) => {
      if (item.datePublished || item.addedDate) {
        const itemDate = item.datePublished || item.addedDate._text;
        return itemDate > date5DaysAgo;
      }
      return false;
    });

    items.sort((a, b) => (
      new Date(b.datePublished || b.addedDate._text)
        - new Date(a.datePublished || a.addedDate._text)
    ));

    hasNotifications = !!items.length;
  }

  return (
    <S.Container>
      <S.Layout
        isShowPopup={isShowPopup}
        onClick={() => {
          setIsShowPopup(false);
        }}
      />

      <S.BellButton
        isShowPopup={isShowPopup}
        hasNotifications={hasNotifications}
        onClick={() => {
          if (hasNotifications) {
            setIsShowPopup(true);
          }
        }}
      >
        <S.BellButtonIcon>{localIcons.bell}</S.BellButtonIcon>

        {items.length > 0 && (
          <S.NumberOfNotifications>{items.length}</S.NumberOfNotifications>
        )}
      </S.BellButton>

      <S.Popup isShowPopup={isShowPopup}>
        <PerfectScrollbar
          className="Notifications-scrollbarContainer"
          options={{
            suppressScrollX: true,
            wheelPropagation: false,
          }}
        >
          <S.PopupContent>
            <S.Items>
              {items.map((item, i) => (
                <S.Item
                  key={i}
                  onClick={() => {
                    setIsShowPopup(false);
                  }}
                >
                  {item.type == 'news' && (
                  <NextLink
                    href={urls.news.item()}
                    as={urls.news.item(item.id)}
                  >
                    <a>
                      <S.ItemTitle type="news">⚡&nbsp;Новости</S.ItemTitle>
                      <S.ItemText>{item.text}</S.ItemText>
                    </a>
                  </NextLink>
                  )}

                  {item.type == 'test' && (
                  <a href={item.viewUrl._text} target="_blank" rel="noreferrer">
                    <S.ItemTitle type="learn">🎓&nbsp;Обучение</S.ItemTitle>
                    <S.ItemText>
                      <div>На тебя назначили новый тест.</div>
                      <div>{item.text}</div>
                    </S.ItemText>
                  </a>
                  )}

                  {item.type == 'course' && (
                  <a href={item.viewUrl._text} target="_blank" rel="noreferrer">
                    <S.ItemTitle type="learn">🎓&nbsp;Обучение</S.ItemTitle>
                    <S.ItemText>
                      <div>У тебя новый курс.</div>
                      <div>{item.text}</div>
                    </S.ItemText>
                  </a>
                  )}

                  {item.type == 'survey' && (
                  <a href={item.viewUrl._text} target="_blank" rel="noreferrer">
                    <S.ItemTitle type="learn">🎓&nbsp;Обучение</S.ItemTitle>
                    <S.ItemText>
                      <div>У тебя новый опрос.</div>
                      <div>{item.text}</div>
                    </S.ItemText>
                  </a>
                  )}
                </S.Item>
              ))}

              {!items.length && 'Нет уведомлений'}
            </S.Items>
          </S.PopupContent>
        </PerfectScrollbar>
      </S.Popup>
    </S.Container>
  );
}

Notifications.propTypes = {};

const mapStateToProps = ({
  userService,
  notificationsService,
  iSpringUsersService,
  iSpringCoursesService,
  iSpringUserResultsService,
}) => {
  const {
    users,

    getUsersSuccess,
    getUsersRequest,
  } = iSpringUsersService;

  const { getCoursesSuccess, getCoursesRequest, courses } = iSpringCoursesService;

  const { userResults, getUserResultsSuccess, getUserResultsRequest } = iSpringUserResultsService;

  return {
    user: userService.user,
    unreadNewsList: notificationsService.unreadNewsList,
    getUnreadNewsRequest: notificationsService.getUnreadNewsRequest,

    users,
    getUsersSuccess,
    getUsersRequest,

    courses,
    getCoursesSuccess,
    getCoursesRequest,

    userResults,
    getUserResultsSuccess,
    getUserResultsRequest,
  };
};

const mapDispatchToProps = {
  getUnreadNewsAction: getUnreadNews,
  resetNotificationsStateAction: resetState,

  getUsersAction: getUsers,
  resetStateUsersAction: resetStateUsers,

  getCoursesAction: getCourses,
  resetStateCoursesAction: resetStateCourses,

  getUserResultsAction: getUserResults,
  resetStateUserResultsAction: resetStateUserResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
