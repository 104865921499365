import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetNewsArticle,
} from './api';

import {
  actionTypes,

  getNewsArticleRequest,
  getNewsArticleSuccess,
  getNewsArticleError,

  updateNewsArticle,
} from './actions';


function* getNewsArticleSaga({ id }) {
  try {

    yield put(getNewsArticleRequest());

    const { data: { data: newsArticle } } = yield call(() => apiGetNewsArticle(id));

    yield put(getNewsArticleSuccess(newsArticle));
  } catch (error) {
    console.log('GET NEWS ARTICLE PUBLIC ERROR', error);
    yield put(getNewsArticleError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_NEWS_ARTICLE_PUBLIC, getNewsArticleSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
