import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';
import queryString from 'query-string';
import Router from 'next/router';

import { showPopup as showErrorPopup } from '../errorPopup/actions';

import {
  apiLogin,
  apiCheckCurrentUserIsBlocked,
} from './api';

import {
  actionTypes,
  loginRequest,
  loginSuccess,
  loginError,
} from './actions';

function redirectAfterLogin() {
  const searchParams = queryString.parse(window.location.search);
  const { callbackPath } = searchParams;

  let url = '/';

  if (callbackPath && callbackPath.length) {
    // When user idle for a long time on a page with a parameter in url
    // for example /news/123 then user will be logged out and redirected
    // to login page with callback path like /news/[id] which is broken
    // for later use. If we see callback path like this then ignore it.
    const isCallbackPathLostParameter = callbackPath.indexOf('[id]') > -1 || callbackPath.indexOf('[usersUnitId]') > -1 || callbackPath.indexOf('[categoryId]') > -1;

    if (callbackPath !== '/_error' && !isCallbackPathLostParameter) {
      url = callbackPath;
    }
  }

  window.location = url;
}

function* loginSaga({ data }) {
  try {
    const loading = yield select((state) => state.loginService.loading);

    if (loading) {
      return;
    }

    yield put(loginRequest());

    let credentials = { ...data };

    credentials.email = credentials.email.trim();
    credentials.email = credentials.email.toLowerCase();

    const loginResponse = yield call(() => apiLogin(credentials));

    if (loginResponse.data.authToken) {
      const checkIsBlockedResponse = yield call(() => apiCheckCurrentUserIsBlocked());

      if (checkIsBlockedResponse.data && !checkIsBlockedResponse.data.isBlocked) {
        yield put(loginSuccess());

        yield call(() => redirectAfterLogin());
      } else {
        yield put(loginError({
          message: 'Юзер заблокирован',
          info: {},
        }));
      }
    } else {
      if (loginResponse.data && loginResponse.data.error_description === 'invalid_username_or_password') {
        yield put(loginError({
          message: 'Неверный логин или пароль',
          info: loginResponse.data,
        }));
      } else {
        yield put(loginError({
          message: 'Неопознанная ошибка ПИК Логин API',
          info: loginResponse.data,
        }));

        yield put(showErrorPopup());
      }
    }
  } catch (error) {
    yield put(loginError({
      message: 'Неопознанная ошибка',
      info: error,
    }));

    yield put(showErrorPopup());
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.LOGIN, loginSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
