export const actionTypes = {
  LOGIN: 'LOGIN',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
};

export const login = data => ({ type: actionTypes.LOGIN, data });

export const loginRequest = () => ({ type: actionTypes.LOGIN_REQUEST });

export const loginSuccess = () => ({ type: actionTypes.LOGIN_SUCCESS });

export const loginError = (error) => ({ type: actionTypes.LOGIN_ERROR, error });
