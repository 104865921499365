export const actionTypes = {
  GET_NEWS_ARTICLE: 'GET_NEWS_ARTICLE',
  GET_NEWS_ARTICLE_REQUEST: 'GET_NEWS_ARTICLE_REQUEST',
  GET_NEWS_ARTICLE_SUCCESS: 'GET_NEWS_ARTICLE_SUCCESS',
  GET_NEWS_ARTICLE_ERROR: 'GET_NEWS_ARTICLE_ERROR',

  LIKE_NEWS_ARTICLE: 'LIKE_NEWS_ARTICLE_PAGE',
  DISLIKE_NEWS_ARTICLE: 'DISLIKE_NEWS_ARTICLE_PAGE',

  UPDATE_NEWS_ARTICLE: 'UPDATE_NEWS_ARTICLE_PAGE',

  RESET_STATE_NEWS_ARTICLE: 'RESET_STATE_NEWS_ARTICLE',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_NEWS_ARTICLE });


export const getNewsArticle = (id) => ({ type: actionTypes.GET_NEWS_ARTICLE, id });

export const getNewsArticleRequest = () => ({ type: actionTypes.GET_NEWS_ARTICLE_REQUEST });

export const getNewsArticleSuccess = (data) => ({ type: actionTypes.GET_NEWS_ARTICLE_SUCCESS, data });

export const getNewsArticleError = (error) => ({ type: actionTypes.GET_NEWS_ARTICLE_ERROR, error });

export const likeNewsArticle = (params) => ({ type: actionTypes.LIKE_NEWS_ARTICLE, ...params });
export const dislikeNewsArticle = (params) => ({ type: actionTypes.DISLIKE_NEWS_ARTICLE, ...params });

export const updateNewsArticle = (params) => ({ type: actionTypes.UPDATE_NEWS_ARTICLE, ...params });
