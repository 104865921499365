import styled from "styled-components";
import { styleHelpers, media } from "@pik/pik-ui";

import { sizes } from "../../../common/styles/const";

const S = {};

S.Container = styled.nav`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  backdrop-filter: blur(12px);

  @media ${media.mobile} {
    height: 60px;
  }
`;

S.Content = styled.div`
  height: 100%;
  padding-left: 23px;
  padding-right: 40px;

  @media ${media.mobile} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

S.Blocks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media ${media.mobile} {
    gap: 10px;
  }
`;

S.LeftBlock = styled.div`
  display: flex;
  align-items: center;
`;

S.LoginLink = styled.a`
  font-size: 14px;
  line-height: 1;
  color: #000;

  &:hover,
  &:active {
    cursor: pointer;
    color: ${styleHelpers.colors.primary};
  }
`;

S.Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  width: 119px;
  height: 68px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  @media ${media.mobile} {
    margin-right: 0px;
  }

  .Icons {
    width: 119px;
    height: 68px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

S.Actions = styled.div`
  display: flex;
`;

S.Action = styled.button`
  margin-right: 22px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #fff;

  &:last-child {
    margin-right: 0;
  }

  svg {
    stroke: #000;
    transition: stroke 0.2s ease;
  }

  &:hover,
  &:active {
    cursor: pointer;

    svg {
      stroke: ${styleHelpers.colors.primary};
    }
  }

  svg {
    transition: all 0.2s ease;
  }
`;

S.AIAction = styled.div`

`;

S.BurgerIcon = styled.div`
  width: 16px;
  height: 14px;
`;

S.SearchIcon = styled.div`
  width: 20px;
  height: 19px;
`;

S.RightBlock = styled.div``;

export default S;
