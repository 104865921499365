import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetNewsArticleUsersReads,
} from './api';

import {
  actionTypes,

  getNewsArticleUsersReadsSuccess,
} from './actions';


function* getNewsArticleUsersReadsSaga(data) {
  try {
    const { data: { data: reads } } = yield call(() => apiGetNewsArticleUsersReads({
      newsId: data.params.newsId,
    }));

    yield put(getNewsArticleUsersReadsSuccess({
      reads,
    }));
  } catch (error) {
    console.log('GET NEWS ARTICLE USERS READS ERROR', error);
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_NEWS_ARTICLE_USERS_READS, getNewsArticleUsersReadsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
