import styled from "styled-components";
import { styleHelpers, media } from "@pik/pik-ui";

import { sizes } from "../../../common/styles/const";

const S = {};

S.Container = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
`;

S.Layout = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s ease-in-out;

  ${({ isShowPopup }) =>
    isShowPopup &&
    `
    opacity: 1;
    pointer-events: all;
  `}
`;

S.BellButtonIcon = styled.div`
  position: relative;
  width: 21px;
  height: 24px;

  svg {
    width: 21px;
    height: 24px;
    stroke: #000;
    transition: stroke 0.2s ease;
  }
`;

S.BellButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 100%;

  ${({ hasNotifications }) =>
    hasNotifications &&
    `
    cursor: pointer;
    border: 2px solid ${styleHelpers.colors.primary};

    &:hover, :active {
    ${S.BellButtonIcon} {
      svg {
        stroke: ${styleHelpers.colors.primary};
      }
    }
  }
  `}

  ${({ isShowPopup }) =>
    isShowPopup &&
    `
    ${S.BellButtonIcon} {
      svg {
        stroke: ${styleHelpers.colors.primary};
      }
    }
  `}
`;

S.NumberOfNotifications = styled.div`
  position: absolute;
  top: -3px;
  right: 0;
  background-color: ${styleHelpers.colors.primary};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

S.Popup = styled.div`
  position: absolute;
  z-index: 2;
  bottom: -467px;
  left: -452px;
  width: 440px;
  height: 468px;
  padding: 3px;
  border-radius: 15px;
  border-top-right-radius: 0px;
  background: #fff;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  pointer-events: none;

  ${({ isShowPopup }) =>
    isShowPopup &&
    `
    opacity: 1;
    pointer-events: all;
  `}

  .Notifications-scrollbarContainer {
    height: 100%;
  }
`;

S.PopupContent = styled.div`
  padding-top: 40px;
  padding-left: 60px;
  padding-right: 60px;
`;

S.Items = styled.ul``;

S.Item = styled.li`
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 19px;

  &:hover,
  &:active {
    a {
      color: ${styleHelpers.colors.primary};
    }
  }

  a {
    color: #000;
    transition: color 0.2s ease;
  }
`;

S.ItemTitle = styled.div`
  position: relative;
  left: -21px;
  margin-bottom: 10px;
  font-weight: ${styleHelpers.fontWeight.semiBold};
`;

S.ItemText = styled.div``;

export default S;
