import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetCultureDocs,
} from './api';

import {
  actionTypes,
  getCultureDocsRequest,
  getCultureDocsSuccess,
  getCultureDocsError,

  getMoreCultureDocsRequest,
  getMoreCultureDocsSuccess,
  getMoreCultureDocsError,
} from './actions';


function* getCultureDocsSaga(data) {
  try {
    yield put(getCultureDocsRequest());
    const { offset, limit } = yield select(({ cultureDocsService }) => cultureDocsService);
    const { user } = yield select(({ userService }) => userService);

    const { data: { data: cultureDocs } } = yield call(() => apiGetCultureDocs({
      offset,
      limit: data.params.limit || limit,
      currentUserGlobalId: user.globalId,
    }));

    yield put(getCultureDocsSuccess({
      cultureDocs,
      offset: offset + limit,
    }));
  } catch (error) {
    console.log('GET CULTURE DOCS ERROR', error);
    yield put(getCultureDocsError(error));
  }
}

function* getMoreCultureDocsSaga({ search }) {
  try {
    yield put(getMoreCultureDocsRequest());
    const { offset, limit } = yield select(({ cultureDocsService }) => cultureDocsService);
    const { user } = yield select(({ userService }) => userService);

    const params = {
      offset,
      limit,
      currentUserGlobalId: user.globalId,
    };

    if (search && search.length) {
      params.search = search;
    }

    const { data: { data: cultureDocs } } = yield call(() => apiGetCultureDocs(params));

    yield put(getMoreCultureDocsSuccess({
      cultureDocs,
      offset: offset + limit,
      isNoMoreCultureDocs: !cultureDocs.length,
    }));
  } catch (error) {
    console.log('MORE CULTURE DOCS ERROR', error);
    yield put(getMoreCultureDocsError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_CULTURE_DOCS, getCultureDocsSaga);
  yield takeEvery(actionTypes.GET_MORE_CULTURE_DOCS, getMoreCultureDocsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
