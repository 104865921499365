import { actionTypes } from './actions';

const initialState = {
  newsArticle: null,

  getNewsArticleRequest: false,
  getNewsArticleSuccess: false,
  getNewsArticleError: false,
};

const handleGetNewsArticleRequest = (state) => ({
  ...state,
  getNewsArticleRequest: true,
  getNewsArticleSuccess: false,
  getNewsArticleError: false,
});

const handleGetNewsArticleSuccess = (state, action) => ({
  ...state,
  getNewsArticleRequest: false,
  getNewsArticleSuccess: true,
  getNewsArticleError: false,
  newsArticle: action.data,
});

const handleGetNewsArticleError = (state) => ({
  ...state,
  getNewsArticleRequest: false,
  getNewsArticleSuccess: false,
  getNewsArticleError: true,
});


const handleUpdateNewsArticle = (state, action) => ({
  ...state,
  newsArticle: action.newsArticle,
});

const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_NEWS_ARTICLE]: handleResetState,

    [actionTypes.GET_NEWS_ARTICLE_REQUEST]: handleGetNewsArticleRequest,
    [actionTypes.GET_NEWS_ARTICLE_SUCCESS]: handleGetNewsArticleSuccess,
    [actionTypes.GET_NEWS_ARTICLE_ERROR]: handleGetNewsArticleError,

    [actionTypes.UPDATE_NEWS_ARTICLE]: handleUpdateNewsArticle,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
