export const actionTypes = {
  GET_NEWS_ARTICLE_PUBLIC: 'GET_NEWS_ARTICLE_PUBLIC',
  GET_NEWS_ARTICLE_PUBLIC_REQUEST: 'GET_NEWS_ARTICLE_PUBLIC_REQUEST',
  GET_NEWS_ARTICLE_PUBLIC_SUCCESS: 'GET_NEWS_ARTICLE_PUBLIC_SUCCESS',
  GET_NEWS_ARTICLE_PUBLIC_ERROR: 'GET_NEWS_ARTICLE_PUBLIC_ERROR',

  UPDATE_NEWS_ARTICLE_PUBLIC: 'UPDATE_NEWS_ARTICLE_PUBLIC_PAGE',

  RESET_STATE_NEWS_ARTICLE_PUBLIC: 'RESET_STATE_NEWS_ARTICLE_PUBLIC',
};

export const resetState = () => ({ type: actionTypes.RESET_STATE_NEWS_ARTICLE_PUBLIC });


export const getNewsArticle = (id) => ({ type: actionTypes.GET_NEWS_ARTICLE_PUBLIC, id });

export const getNewsArticleRequest = () => ({ type: actionTypes.GET_NEWS_ARTICLE_PUBLIC_REQUEST });

export const getNewsArticleSuccess = (data) => ({ type: actionTypes.GET_NEWS_ARTICLE_PUBLIC_SUCCESS, data });

export const getNewsArticleError = (error) => ({ type: actionTypes.GET_NEWS_ARTICLE_PUBLIC_ERROR, error });

export const updateNewsArticle = (params) => ({ type: actionTypes.UPDATE_NEWS_ARTICLE_PUBLIC, ...params });
