
export default {
  burger: <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1H15" strokeWidth="2" strokeLinecap="round"/>
<path d="M1 7H15" strokeWidth="2" strokeLinecap="round"/>
<path d="M1 13H15" strokeWidth="2" strokeLinecap="round"/>
</svg>,
  search: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5732 16.5732L12.5732 12.5732" strokeWidth="2" strokeLinecap="round"/>
<path d="M9 4C11.7614 4 14 6.23858 14 9C14 11.7614 11.7614 14 9 14C6.23858 14 4 11.7614 4 9C4 6.23858 6.23858 4 9 4Z" strokeWidth="2"/>
</svg>,
};
