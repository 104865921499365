import { actionTypes } from './actions';

const initialState = {
  getNewsRequest: false,
  getNewsSuccess: false,
  getNewsError: false,
  getMoreNewsRequest: false,
  getMoreNewsSuccess: false,
  getMoreNewsError: false,
  newsList: [],
  limit: 20,
  offset: 0,
  isNoMoreNews: false,
};

const handleGetNewsRequest = (state) => ({
  ...state,
  getNewsRequest: true,
  getNewsSuccess: false,
  getNewsError: false,
});

const handleGetNewsSuccess = (state, action) => ({
  ...state,
  getNewsRequest: false,
  getNewsSuccess: true,
  getNewsError: false,
  newsList: action.news,
  offset: action.offset,
  isNoMoreNews: !action.news.length,
});

const handleGetNewsError = (state) => ({
  ...state,
  getNewsRequest: false,
  getNewsSuccess: false,
  getNewsError: true,
});


const handleGetMoreNewsRequest = (state) => ({
  ...state,
  getMoreNewsRequest: true,
  getMoreNewsSuccess: false,
  getMoreNewsError: false,
});

const handleGetMoreNewsSuccess = (state, action) => ({
  ...state,
  getMoreNewsRequest: false,
  getMoreNewsSuccess: true,
  getMoreNewsError: false,
  newsList: [...state.newsList, ...action.news],
  offset: action.offset,
  isNoMoreNews: !action.news.length,
});

const handleGetMoreNewsError = (state) => ({
  ...state,
  getMoreNewsRequest: false,
  getMoreNewsSuccess: false,
  getMoreNewsError: true,
});

const handleUpdateNewsArticle = (state, action) => ({
  ...state,
  newsList: action.newsList,
});

const handleResetState = () => ({
  ...initialState,
});

const handleChangeOffset = (state, action) => ({
  ...state,
  offset: action.offset,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_PROJECTS_NEWS]: handleResetState,
    [actionTypes.CHANGE_OFFSET_PROJECTS_NEWS]: handleChangeOffset,

    [actionTypes.GET_PROJECTS_NEWS_REQUEST]: handleGetNewsRequest,
    [actionTypes.GET_PROJECTS_NEWS_SUCCESS]: handleGetNewsSuccess,
    [actionTypes.GET_PROJECTS_NEWS_ERROR]: handleGetNewsError,

    [actionTypes.GET_MORE_PROJECTS_NEWS_REQUEST]: handleGetMoreNewsRequest,
    [actionTypes.GET_MORE_PROJECTS_NEWS_SUCCESS]: handleGetMoreNewsSuccess,
    [actionTypes.GET_MORE_PROJECTS_NEWS_ERROR]: handleGetMoreNewsError,

    [actionTypes.UPDATE_PROJECTS_NEWS_ARTICLE]: handleUpdateNewsArticle,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
