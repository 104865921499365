export const actionTypes = {
  GET_PROJECTS_NEWS: 'GET_PROJECTS_NEWS',
  GET_PROJECTS_NEWS_REQUEST: 'GET_PROJECTS_NEWS_REQUEST',
  GET_PROJECTS_NEWS_SUCCESS: 'GET_PROJECTS_NEWS_SUCCESS',
  GET_PROJECTS_NEWS_ERROR: 'GET_PROJECTS_NEWS_ERROR',

  GET_MORE_PROJECTS_NEWS: 'GET_MORE_PROJECTS_NEWS',
  GET_MORE_PROJECTS_NEWS_REQUEST: 'GET_MORE_PROJECTS_NEWS_REQUEST',
  GET_MORE_PROJECTS_NEWS_SUCCESS: 'GET_MORE_PROJECTS_NEWS_SUCCESS',
  GET_MORE_PROJECTS_NEWS_ERROR: 'GET_MORE_PROJECTS_NEWS_ERROR',

  UPDATE_PROJECTS_NEWS_ARTICLE: 'UPDATE_PROJECTS_NEWS_ARTICLE',

  CHANGE_OFFSET_PROJECTS_NEWS: 'CHANGE_OFFSET_PROJECTS_NEWS',
  RESET_STATE_PROJECTS_NEWS: 'RESET_STATE_PROJECTS_NEWS',
};

export const changeOffset = (offset) => ({ type: actionTypes.CHANGE_OFFSET_PROJECTS_NEWS, offset });

export const resetState = () => ({ type: actionTypes.RESET_STATE_PROJECTS_NEWS });


export const getNews = (params) => ({ type: actionTypes.GET_PROJECTS_NEWS, params });

export const getNewsRequest = () => ({ type: actionTypes.GET_PROJECTS_NEWS_REQUEST });

export const getNewsSuccess = (params) => ({ type: actionTypes.GET_PROJECTS_NEWS_SUCCESS, ...params });

export const getNewsError = (error) => ({ type: actionTypes.GET_PROJECTS_NEWS_ERROR, error });


// pagination
export const getMoreNews = (params) => ({ type: actionTypes.GET_MORE_PROJECTS_NEWS, ...params });

export const getMoreNewsRequest = () => ({ type: actionTypes.GET_MORE_PROJECTS_NEWS_REQUEST });

export const getMoreNewsSuccess = (params) => ({
  type: actionTypes.GET_MORE_PROJECTS_NEWS_SUCCESS, ...params,
});

export const getMoreNewsError = (error) => ({ type: actionTypes.GET_MORE_PROJECTS_NEWS_ERROR, error });

export const updateNewsArticle = (params) => ({ type: actionTypes.UPDATE_PROJECTS_NEWS_ARTICLE, ...params });
