import { actionTypes } from './actions';

const initialState = {
  getDocsRequest: false,
  getDocsSuccess: false,
  getDocsError: false,
  docs: [],
};

const handleGetCommonDocsRequest = (state) => ({
  ...state,
  getDocsRequest: true,
  getDocsSuccess: false,
  getDocsError: false,
});

const handleGetCommonDocsSuccess = (state, action) => ({
  ...state,
  getDocsRequest: false,
  getDocsSuccess: true,
  getDocsError: false,
  docs: action.docs,
});

const handleGetCommonDocsError = (state) => ({
  ...state,
  getDocsRequest: false,
  getDocsSuccess: false,
  getDocsError: true,
});

const handleResetState = () => ({
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_STATE_MY_TEAM_LATEST_DOCS]: handleResetState,

    [actionTypes.GET_MY_TEAM_LATEST_DOCS_REQUEST]: handleGetCommonDocsRequest,
    [actionTypes.GET_MY_TEAM_LATEST_DOCS_SUCCESS]: handleGetCommonDocsSuccess,
    [actionTypes.GET_MY_TEAM_LATEST_DOCS_ERROR]: handleGetCommonDocsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
