import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';

import { Icons } from '@pik/pik-ui';

import urls from '../../../common/urls';

import { getCurrentUser } from '../../../services/user/actions';

import { open as openSideNav } from '../../../services/sideNav/actions';

import { open as openSearch } from '../../../services/search/actions';

import User from '../../auth/User';

import AILoginButton from '../../common/AILoginButton';

import localIcons from './icons';

import S from './styles';

function TopNav(props) {
  const {
    isAuthorized,

    screenType,
    user,

    getCurrentUserAction,
    openSideNavAction,
    openSearchAction,
  } = props;

  const router = useRouter();
  const currentRoute = router.pathname;

  const [isShowSearch, setIsShowSearch] = useState(false);

  useEffect(() => {
    if (
      currentRoute.indexOf('/docs') > -1
      || currentRoute.indexOf('/CoursesCatalog') > -1
      || currentRoute.indexOf('/coursesCatalog') > -1
    ) {
      setIsShowSearch(true);
    } else {
      setIsShowSearch(false);
    }
  }, [currentRoute, isShowSearch, setIsShowSearch]);

  useEffect(() => {
    if (isAuthorized) {
      getCurrentUserAction();
    }
  }, [isAuthorized]);

  let loginLink = urls.login;

  if (router.asPath) {
    loginLink += `?callbackPath=${router.asPath}`;
  } else if (router.pathname) {
    loginLink += `?callbackPath=${router.pathname}`;
  }

  return (
    <S.Container>
      <S.Content>
        <S.Blocks>
          <S.LeftBlock>
            <S.Logo>
              <NextLink
                href={isAuthorized ? '/' : `${urls.login}?callbackPath=/`}
              >
                <a>
                  <Icons type="logo" size="m" />
                </a>
              </NextLink>
            </S.Logo>

            <S.Actions>
              <S.Action
                onClick={(e) => {
                  e.stopPropagation();

                  openSideNavAction();
                }}
              >
                <S.BurgerIcon>{localIcons.burger}</S.BurgerIcon>
              </S.Action>

              {user && isShowSearch && (
                <S.Action
                  onClick={(e) => {
                    e.stopPropagation();

                    openSearchAction();
                  }}
                >
                  <S.SearchIcon>{localIcons.search}</S.SearchIcon>
                </S.Action>
              )}

              {user &&
                <S.AIAction>
                  <AILoginButton />
                </S.AIAction>
              }
            </S.Actions>
          </S.LeftBlock>
          <S.RightBlock>
            {user ? (
              <User user={user} />
            ) : (
              <NextLink href={loginLink} passHref>
                <S.LoginLink>Войти</S.LoginLink>
              </NextLink>
            )}
          </S.RightBlock>
        </S.Blocks>
      </S.Content>
    </S.Container>
  );
}

TopNav.propTypes = {
  screenType: PropTypes.string.isRequired,
};

const mapStateToProps = ({ appService, userService }) => ({
  screenType: appService.screenType,
  user: userService.user,
});

const mapDispatchToProps = {
  getCurrentUserAction: getCurrentUser,
  openSideNavAction: openSideNav,
  openSearchAction: openSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
