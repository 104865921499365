export const actionTypes = {
  GET_NEWS: 'GET_NEWS',
  GET_NEWS_REQUEST: 'GET_NEWS_REQUEST',
  GET_NEWS_SUCCESS: 'GET_NEWS_SUCCESS',
  GET_NEWS_ERROR: 'GET_NEWS_ERROR',

  GET_MORE_NEWS: 'GET_MORE_NEWS',
  GET_MORE_NEWS_REQUEST: 'GET_MORE_NEWS_REQUEST',
  GET_MORE_NEWS_SUCCESS: 'GET_MORE_NEWS_SUCCESS',
  GET_MORE_NEWS_ERROR: 'GET_MORE_NEWS_ERROR',

  LIKE_NEWS_ARTICLE: 'LIKE_NEWS_ARTICLE',
  DISLIKE_NEWS_ARTICLE: 'DISLIKE_NEWS_ARTICLE',

  UPDATE_NEWS_ARTICLE: 'UPDATE_NEWS_ARTICLE',

  CHANGE_OFFSET: 'CHANGE_OFFSET',
  RESET_STATE: 'RESET_STATE',
};

export const changeOffset = (offset) => ({ type: actionTypes.CHANGE_OFFSET, offset });

export const resetState = () => ({ type: actionTypes.RESET_STATE });


export const getNews = (params) => ({ type: actionTypes.GET_NEWS, params });

export const getNewsRequest = () => ({ type: actionTypes.GET_NEWS_REQUEST });

export const getNewsSuccess = (params) => ({ type: actionTypes.GET_NEWS_SUCCESS, ...params });

export const getNewsError = (error) => ({ type: actionTypes.GET_NEWS_ERROR, error });


// pagination
export const getMoreNews = (params) => ({ type: actionTypes.GET_MORE_NEWS, ...params });

export const getMoreNewsRequest = () => ({ type: actionTypes.GET_MORE_NEWS_REQUEST });

export const getMoreNewsSuccess = (params) => ({
  type: actionTypes.GET_MORE_NEWS_SUCCESS, ...params,
});

export const getMoreNewsError = (error) => ({ type: actionTypes.GET_MORE_NEWS_ERROR, error });


export const likeNewsArticle = (params) => ({ type: actionTypes.LIKE_NEWS_ARTICLE, ...params });
export const dislikeNewsArticle = (params) => ({ type: actionTypes.DISLIKE_NEWS_ARTICLE, ...params });

export const updateNewsArticle = (params) => ({ type: actionTypes.UPDATE_NEWS_ARTICLE, ...params });

