import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetNewsArticle,
  apiLikeNewsArticle,
  apiDislikeNewsArticle,
} from './api';

import {
  actionTypes,

  getNewsArticleRequest,
  getNewsArticleSuccess,
  getNewsArticleError,

  updateNewsArticle,
} from './actions';


function* getNewsArticleSaga({ id }) {
  try {
    const { user } = yield select(({ userService }) => userService);

    yield put(getNewsArticleRequest());

    const { data: { data: newsArticle } } = yield call(() => apiGetNewsArticle(id, {
      currentUserGlobalId: user.globalId,
    }));

    yield put(getNewsArticleSuccess(newsArticle));
  } catch (error) {
    console.log('GET NEWS ARTICLE ERROR', error);
    yield put(getNewsArticleError(error));
  }
}


function* likeNewsArticleSaga({ newsId }) {
  try {
    const { newsArticle } = yield select(({ newsArticleService }) => newsArticleService);
    const { user } = yield select(({ userService }) => userService);

    const likes = newsArticle.likes ? newsArticle.likes + 1 : 1;

    // Like News Article in state right away
    yield put(updateNewsArticle({
      newsArticle: {
        ...newsArticle,
        isLiked: true,
        likes,
      },
    }));

    // Like API call
    yield call(() => apiLikeNewsArticle({
      newsId,
      userGlobalId: user.globalId,
    }));
  } catch (error) {
    console.log('likeNewsArticleSaga ERROR', error);
  }
}


function* dislikeNewsArticleSaga({ newsId }) {
  try {
    const { newsArticle } = yield select(({ newsArticleService }) => newsArticleService);
    const { user } = yield select(({ userService }) => userService);

    const likes = newsArticle.likes ? newsArticle.likes - 1 : 0;

    // Dislike News Article in state right away
    yield put(updateNewsArticle({
      newsArticle: {
        ...newsArticle,
        isLiked: false,
        likes,
      },
    }));

    // Like API call
    yield call(() => apiDislikeNewsArticle({
      newsId,
      userGlobalId: user.globalId,
    }));
  } catch (error) {
    console.log('likeNewsArticleSaga ERROR', error);
  }
}


function* watchSagas() {
  yield takeEvery(actionTypes.GET_NEWS_ARTICLE, getNewsArticleSaga);
  yield takeEvery(actionTypes.LIKE_NEWS_ARTICLE, likeNewsArticleSaga);
  yield takeEvery(actionTypes.DISLIKE_NEWS_ARTICLE, dislikeNewsArticleSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
