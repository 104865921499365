import { makeAutoObservable } from "mobx";
import uuid from "react-uuid";

import Userstore from "./user";
import AccountStore from "./account";

const url =
  process.env.NODE_ENV === "production"
    ? "wss://lkdp-api.pik.team/api"
    : "ws://localhost:3005/api/";

class Websocket {
  notifications = [];

  constructor() {
    makeAutoObservable(this);
  }

  connectWS = () => {
    const socket = new WebSocket(url);
    socket.onopen = () => {
      socket.send(
        JSON.stringify({
          method: "connection",
          user: Userstore.user,
        })
      );
    };

    const pong = () => {
      setTimeout(() => {
        socket.send(
          JSON.stringify({
            method: "pong",
            user: Userstore.user,
          })
        );
      }, 5000);
    };

    socket.onmessage = (e) => {
      const data = JSON.parse(e.data);

      const taskItemPage =
        window.location.pathname.startsWith("/account/task/");

      if (data.type === "ping") {
        pong();
      } else if (data.type === "Комментарий") {
        data.id = uuid();
        this.notifications.push(data);
      } else if (
        data.type === "Обновление задачи" &&
        AccountStore.task?.id === data.data.id
      ) {
        AccountStore.setTask(data.data);
      }

      if (taskItemPage) AccountStore.getComments();
    };
  };

  deleteNotification = (id) => {
    this.notifications = [...this.notifications.filter((el) => el.id !== id)];
  };
}

export default new Websocket();
