export default {
  close: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36px"
      height="36px"
      viewBox="0 0 36 36"
      version="1.1"
    >
      <g id="surface1">
        <path
          d="M 30.671875 8.46875 C 31.539062 7.601562 31.539062 6.195312 30.671875 5.328125 C 29.804688 4.460938 28.398438 4.460938 27.53125 5.328125 L 18 14.859375 L 8.46875 5.328125 C 7.601562 4.460938 6.195312 4.460938 5.328125 5.328125 C 4.460938 6.195312 4.460938 7.601562 5.328125 8.46875 L 14.859375 18 L 5.328125 27.53125 C 4.460938 28.398438 4.460938 29.804688 5.328125 30.671875 C 6.195312 31.539062 7.601562 31.539062 8.46875 30.671875 L 18 21.140625 L 27.53125 30.671875 C 28.398438 31.539062 29.804688 31.539062 30.671875 30.671875 C 31.539062 29.804688 31.539062 28.398438 30.671875 27.53125 L 21.140625 18 Z M 30.671875 8.46875 "
        />
      </g>
    </svg>
  ),
};
